import React, {
  useCallback,
  useState,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import Cookies from 'js-cookie';

import { http } from '../helpers/http';

const AuthContext = createContext(null);

// Por el momento el loggin es muy básico, únicamente se rige por si el objeto está lleno  o no,
// especialmente el atributo "logged"
const initialState = {
  id: null,
  logged: false,
  mail: null,
  name: null,
};

export function AuthProvider({ children }) {
  const [auth, setAuth] = useState(initialState);

  const login = useCallback(async (mail, pass) => {
    try {
      const response = await http.post('/dashboard/login', {
        mail,
        pass,
      });

      const { data } = response;
      if (data.allow) {
        const value = {
          id: data.data[0].id_user,
          id_client: data.data[0].id_client,
          logged: !!data.data[0].id_user,
          name: data.data[0].nombre,
          mail: data.data[0].mail,
          tipo: data.data[0].tipo,
          //ubicacion: data.data[0].ubicacion,
        };

        setAuth(value);
        // Estas cookies simplemente sirven para trabajar un poco más ágil.
        // Se mantienen mientras se siga en la misma sesión de la PC o naturalmente
        // cuando se desloggea el usuario
        Cookies.set('wiwi-campaigns-login', JSON.stringify(value));
      } else {
        throw new Error('Unauthorized.');
      }

      return true;
    } catch {
      setAuth({
        id: null,
        logged: false,
        mail: null,
        name: null,
        id_client: null,
        tipo: null,
        ubicacion: null,
      });

      Cookies.remove('wiwi-campaigns-login');
    }

    return false;
  }, [setAuth]);

  const logout = useCallback(() => {
    setAuth({
      id: null,
      logged: false,
      mail: null,
      name: null,
      id_client: null,
      tipo: null,
      ubicacion: null,
    });

    Cookies.remove('wiwi-campaigns-login');
  }, [setAuth]);

  useEffect(() => {
    const cookieValue = Cookies.get('wiwi-campaigns-login');

    if (cookieValue) {
      const value = JSON.parse(cookieValue);
      setAuth(value);
    }
  }, []);

  const value = useMemo(() => ({
    auth,
    login,
    logout,
  }), [auth, login, logout]);

  return (
    <AuthContext.Provider value={value}>
      { children }
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);
