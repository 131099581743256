import React, { useState, useEffect } from 'react';
import {
  Chart as CharJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import NumberFormat from 'react-number-format';
CharJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function ScreenOne({ screenData,concesion,porcentajeConcesion }) {
  const colores=[["#ffe0e6","#fc7391"],["rgba(153, 204, 255, 1)","rgba(77,166,255,1)"],["#fff5de","#ffce57"],
  ["#dbf2f2","#4bc0c0"],["#ebe0ff","#9966ff"],["#70d44b40","#70d44b"],
  ["rgba(153, 204, 255, 0.95)","rgba(77,166,255,0.95)"],
  ["rgba(153, 204, 255, 0.9)","rgba(77,166,255,0.9)"],
  ["rgba(153, 204, 255, 0.85)","rgba(77,166,255,0.85)"],
  ["rgba(153, 204, 255, 0.8)","rgba(77,166,255,0.8)"],
  ["rgba(153, 204, 255, 0.75)","rgba(77,166,255,0.75)"],
  ["rgba(153, 204, 255, 0.7)","rgba(77,166,255,0.7)"],
  ["rgba(153, 204, 255, 0.65)","rgba(77,166,255,0.65)"],
  ["rgba(153, 204, 255, 0.6)","rgba(77,166,255,0.6)"],
  ["rgba(153, 204, 255, 0.55)","rgba(77,166,255,0.55)"],
  ["rgba(153, 204, 255, 0.5)","rgba(77,166,255,0.5)"],
  ["rgba(153, 204, 255, 0.45)","rgba(77,166,255,0.45)"],
  ["rgba(153, 204, 255, 0.4)","rgba(77,166,255,0.4)"],
  ["rgba(153, 204, 255, 0.35)","rgba(77,166,255,0.35)"],
  ["rgba(153, 204, 255, 0.3)","rgba(77,166,255,0.3)"],
  ["rgba(153, 204, 255, 0.25)","rgba(77,166,255,0.25)"],
  ["rgba(153, 204, 255, 0.2)","rgba(77,166,255,0.2)"],
  ["rgba(153, 204, 255, 0.15)","rgba(77,166,255,0.15)"],
  ["rgba(153, 204, 255, 0.1)","rgba(77,166,255,0.1)"],



 

];
 


  const valores = Object.values(screenData?.valores);

  const titulos= Object.keys(screenData?.valores);

  let titles = [];
  let values= [];


  for (let i = 0; i < 5; i++) {
    //console.log(titulos[i]);
    if(valores[i]!=0){
      if(titulos[i]=="impresiones"){
        values.unshift(valores[i]);
        titles.unshift((titulos[i][0]).toUpperCase() + titulos[i].slice(1).toLowerCase());
      }
      if(titulos[i]=="interacciones"){
        values.splice(1, 0, valores[i]);        
        titles.splice(1, 0, (titulos[i][0]).toUpperCase() + titulos[i].slice(1).toLowerCase());
      }
      if(titulos[i]=="conversiones"){
        values.unshift(valores[i]);
        titles.unshift((titulos[i][0]).toUpperCase() + titulos[i].slice(1).toLowerCase());
      }

      if(titulos[i]=="numero_concesiones" && concesion){
        values.unshift(valores[i]);
        titles.unshift("Concesiones");
      }
    }
  }


  //console.log("Antes de agregar ctr y conv");
  //console.log(titles);
  //console.log(values);

  if(values.length >=2){
    //console.log(titles);
   
     if (titles.find(element => element === "Interacciones")!=undefined){
      
      values.push(((values[1]/values[0])*100).toFixed(2));
      titles.push("CTR");
    }else{
     
      values.push(((values[1]/values[0])*100).toFixed(2));
      titles.push("CONV");
    }
    
  }

  if(values.length >=3){
    if (titles.find(element => element === "Interacciones")!=undefined){
    values.push(((values[2]/values[1])*100).toFixed(2));
    titles.push("CONV");
    }
  }

  
  let elementos= Object.keys(valores[3]);
  let valoresElementos= Object.values(valores[3]);


  



  let arregloElementos=[];



  let objetoImpresiones={
    //label: "Impresiones",
    backgroundColor: ['#ffe0e6'],
    borderColor: ['#fc7391'],
    borderWidth: 2,
    borderRadius: 0,
    data: [parseInt(values.slice(0, 1))],

    datalabels: {
      color: '#999',
      anchor: 'end',
      align: 'top',
      formatter: (value) => {
        return  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },

  }

  arregloElementos.push(objetoImpresiones);



if(titles.find(element => element === "Conversiones")!=undefined){
  let objetoConversaciones={
    backgroundColor: ['#fff5de'],
    borderColor: ['#ffce57'],
    borderWidth: 2,
    borderRadius: 0,
    data: titles.find(element => element === "Interacciones")!=undefined?[null,null,parseInt(values.slice(2, 3))]:[null,parseInt(values.slice(1,2))],
    datalabels: {
      color: '#999',
      anchor: 'end',
      align: 'top',
      formatter: (value) => {
        if (value!=null){
          return  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        
       // return  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
  }

  arregloElementos.push(objetoConversaciones);
}

  for (let i = 0; i < elementos.length; i++) {
    let objeto={
      backgroundColor: colores[i+6][0],
      borderColor: colores[i+6][1],
      borderWidth: 2,
      borderRadius: 0,
      label: elementos[i],
      data: [null, valoresElementos[i]],
      datalabels: {
        //color: 'black',
        //anchor: 'end',
       // align: 'top',
        formatter: (value) => {
          if (value!=null){
            return  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          }
         // return  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
      },
    }
    arregloElementos.push(objeto);
  }





 // console.log("arregloElementos",arregloElementos);

  

  const [chartData, setChartData] = useState({
    datasets: [],
  });

  const [chartOptions, setChartOptions] = useState({});



  useEffect(() => {
    setChartData({
      labels: titles.length < 3 ? titles : titles.find(element => element === "Interacciones")!=undefined?titles.slice(0, 3):titles.slice(0, 2),
      datasets: 
        arregloElementos,
         
    }
      );
    setChartOptions({
      responsive: true,
      scales: {
        x: {
          ticks: {
            display: false,
          },
          stacked: true,
          
        },
        y: {
          beginAtZero: true,
          suggestedMin: 0,
          suggestedMax: Math.max(...values)+(Math.max(...values)*.1),
          stacked: true,
        },
        
      },
      plugins: {
        legend: {
          display: false,
          position: 'bottom',
        },
      },
    });
  }, []);


 
  if (!screenData) {
    return <>Esta gráfica no está disponible por el momento ❌</>;
  }
  

 // console.log(titles);

  return (
    <>
    
      <Bar options={chartOptions} data={chartData} width={100} height={100} />
     
      <ul className="legends ml-0">

        {
        
        titles.map(function(title, index){
          if(values[index] > 0){
          return  (
            title=="Interacciones"?
              <>

              <li key={index}>
                <small style={{ background: colores[index][0], border: `2px solid ${colores[index][1]}` }} />
                <NumberFormat
                  value={values[index]}
                  displayType={'text'}
                  thousandSeparator={true}
                  renderText={(value, props) => <strong {...props}>{title+ `: ${value}`}</strong>}
                />
              </li>
              
              
              {
                elementos.map(function(elemento, indexE){
                  return  (
                    <li key={index} style={{paddingLeft:20}}>
                    <small style={{ background: colores[indexE+6][0], border: `2px solid ${colores[indexE+6][1]}` }} />
                    <NumberFormat
                      value={valoresElementos[indexE]}
                      displayType={'text'}
                      thousandSeparator={true}
                      renderText={(value, props) => <strong {...props}>{elemento+ `:   ${value} / ${((valoresElementos[indexE]/values[index])*100).toFixed(2)}% / ${((valoresElementos[indexE]/values[index-1])*100).toFixed(2)}% `  }</strong>}
                    />
                  </li>
                  )
                }
                )

            
              }
              </>
            :
            <li key={index}>
                <small style={{ background: title=="Impresiones"?colores[0][0]:title=="Interacciones"?colores[1][0]:title=="Conversiones"?colores[2][0]:title=="CONV"?colores[4][0]:colores[index][0], border: `2px solid ${ title=="Impresiones"?colores[0][1]:title=="Interacciones"?colores[1][1]:title=="Conversiones"?colores[2][1]:title=="CONV"?colores[4][1]:colores[index][1]}` }} />
                <NumberFormat
                  value={values[index]}
                  displayType={'text'}
                  thousandSeparator={true}
                  renderText={(value, props) => <strong {...props}>{title + (title=="Concesiones"?`: ${value} (`:`: ${value} ` ) + (title=="CTR" || title == "CONV" ?'%':'' || title=="Concesiones"? porcentajeConcesion+'%)' : '') }</strong>}
                />
              </li>
            )
          }
        })


        // titles.map((title, index) => (
        //   <li key={index}>
        //   <small style={{ background: colores[index][0], border: `2px solid ${colores[index][1]}` }} />
        //      <NumberFormat
        //            value={values[index]}
        //            displayType={'text'}
        //            thousandSeparator={true}
        //            renderText={(value, props) => <strong {...props}>{title+ `: ${value}`}</strong>}
        //     />
        //   </li>
        //   ))
        }
      </ul>
    </>
  );
}

export default ScreenOne;
