import React, { useState, useEffect } from 'react';
import {
  Chart as CharJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import NumberFormat from 'react-number-format';

CharJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function Users({ usersInfo }) {
  const [chartInfo] = useState(usersInfo);
  const [chartData, setChartData] = useState({
    datasets: [],
  });
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    setChartData({
      labels: ['Total', 'Únicos', 'Prom. Usu / Día'],
      datasets: [
        {
          data: chartInfo.slice(0, 3),
          backgroundColor: ['rgba(75, 192, 192, 0.2)', 'rgba(153, 102, 255, 0.2)', 'rgba(255, 159, 64, 0.2)'],
          borderColor: ['rgba(75, 192, 192, 1)', 'rgba(153, 102, 255, 1)', 'rgba(255, 159, 64, 1)'],
          borderWidth: 2,
          borderRadius: 0,
          datalabels: {
            color: '#999',
            anchor: 'end',
            align: 'top',
            formatter: (value) => {
              return  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
          },
        },
      ],
    });
    setChartOptions({
      responsive: true,
      plugins: {
        legend: {
          display: false,
          position: 'bottom',
        },
      },
      scales: {
        y: {
            beginAtZero: true,
            suggestedMin: 0,
            suggestedMax: Math.max(...chartInfo)+(Math.max(...chartInfo)*.1),
        }
    }
    });
  }, [chartInfo[0]]);

  if (!chartInfo[0]) {
    return <>Esta gráfica no está disponible por el momento ❌</>;
  }

  return (
    <>
      <Bar options={chartOptions} data={chartData} width={100} height={100} />
      <ul className="legends ml-0">
        <li>
          <small style={{ background: '#dbf2f2', border: '2px solid #4bc0c0' }} />
          <NumberFormat
                  value={chartInfo[0]}
                  displayType={'text'}
                  thousandSeparator={true}
                  renderText={(value, props) => <strong {...props}>{`Totales: ${value}`}</strong>}
                />
          
        </li>
        <li>
          <small style={{ background: '#ebe0ff', border: '2px solid #9966ff' }} />
          <NumberFormat
                  value={chartInfo[1]}
                  displayType={'text'}
                  thousandSeparator={true}
                  renderText={(value, props) => <strong {...props}>{`Únicos: ${value}`}</strong>}
                />
          
        </li>
        <li>
          <small style={{ background: '#ffecda', border: '2px solid #ff9f40' }} />
          <NumberFormat
                  value={chartInfo[2]}
                  displayType={'text'}
                  thousandSeparator={true}
                  renderText={(value, props) => <strong {...props}>{`Promedio Usuarios por día: ${value}`}</strong>}
                />

       
        </li>
        <li>
          <small style={{ background: '#fff5de', border: '2px solid #ffce57' }} />
          <NumberFormat
                  value={chartInfo[3]}
                  displayType={'text'}
                  thousandSeparator={true}
                  renderText={(value, props) => <strong {...props}>{`Impactos Promedio por Usuario: ${value}`}</strong>}
                />
        
        </li>
      </ul>
    </>
  );
}

export default Users;
