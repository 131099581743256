import React from 'react';

function ScreenPreview({ titulo, url,obligatorio=false }) {

  const estiloIframe = {
    width: '320px',
    height: '565px',
    border: '2px solid #ccc'
  };

  const styleTituloCard = {
    color: '#999',
    fontSize: '16px',
    marginBottom: '20px',
    fontWeight: 'bold'
  };

  return (
    <div className="column">
      <p style={styleTituloCard}>{titulo} {obligatorio ? <span className="is-required" >(Obligatorio)</span> : null}</p>
      <iframe title={titulo} src={url} style={estiloIframe} className="frame" sandbox="allow-same-origin" />
    </div>
  );
}

export default ScreenPreview;
