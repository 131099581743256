import React, {
  useCallback, useEffect, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { http } from '../../helpers/http';
import { optionsDate } from '../../helpers/formatter';
import KPI from './LogComponents/KPI';
import LogTable from './LogComponents/LogTable';
import Spinner from '../Spinner/Spinner';

import 'react-datepicker/dist/react-datepicker.css';

// Icons
import {FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons'

function Log() {
  const initialState = {
    TImpress: 0,
    TImpact: 0,
    UnicU: 0,
    Male: 0,
    Female: 0,
    ageU18: 0,
    age18: 0,
    age25: 0,
    age35: 0,
    age45: 0,
    age50: 0,
    age55: 0,
    ageU65: 0,
  };


  const styleTituloCard = {
    color: '#999',
    fontSize: '12px',
    marginBottom: '5px',
  };

  //const [loading, setLoading] = useState(true);

  const [startDate, setStartDate] = useState();
  // Aquí se guarda el input de la ùltima fecha a consultar por parte del cliente
  const [endDate, setEndDate] = useState();

  const [numeroDePantallas, setNumeroDePantallas] = useState([]);

  const [loadingRegistros, setLoadingRegistros] = useState(true);
  const [loadingImpresiones, setLoadingImpresiones] = useState(true);
  const [loadingImpresionesGenero, setLoadingImpresionesGenero] = useState(true);


  const [dateMin, setdateMin] = useState();
  const [dateMax, setdateMax] = useState();


  // Acá se guarda la pantalla que quiere consultar el cliente, si el número es cero,
  // se hace un fetch de todas las pantallas.
  // IMPORTANTE el fetch de las pantallas se hace de forma automática (en el OnChange),
  // porque así lo requirió el cliente.
  const [selectedScreen, setSelectedScreen] = useState(0);

  const [primerCarga, setPrimerCarga] = useState(true);

  const [infoKPI, setInfoKPI] = useState(initialState);

  const [impresiones, setImpresiones] = useState([]);
  const [impresionesGenero, setImpresionesGenero] = useState([]);


  const [impresionesDiarias, setImpresionesDiarias] = useState(0);

  //const [tableInfo, setTableInfo] = useState([]);

  const handleStartDate = (event) => {
    if(event > endDate) {
      setEndDate(event);
    }

   setStartDate(event);
  };

  const handleEndDate = (event) => {
    setEndDate(event);
  };
  // De la misma forma, aquí se toma el ID de la campaña y se pasa por params
  const { id: campaignId } = useParams();

  async function fetchCampaignDate() {
    try {
      const response = await http.get('records/lista_camp', {
        params: {
          id_camp: campaignId,
        },
      });
      const { data } = response;
     
      const formatted = new Intl.DateTimeFormat('es-MX', optionsDate).format(new Date(data.data[0].inicio));
      const yyyymmdd = formatted.split('/').reverse().join('-');
      const dateArray = yyyymmdd.split("-");
      const year = dateArray[0];
      const month = parseInt(dateArray[1], 10) - 1;
      const date = dateArray[2];
      const _entryDate = new Date(year, month, date);

      setdateMin(new Date(_entryDate));
      setStartDate(new Date(_entryDate));

      const fechafin=new Date(data.data[0].fin);
      let formatted2;
      if (fechafin>new Date()) {
         formatted2 = new Intl.DateTimeFormat('es-MX', optionsDate).format(new Date());
      } else {
         formatted2 = new Intl.DateTimeFormat('es-MX', optionsDate).format(new Date(data.data[0].fin));
      }

     

      const yyyymmdd2 = formatted2.split('/').reverse().join('-');
 

      const dateArray2 = yyyymmdd2.split("-");
      const year2 = dateArray2[0];
      const month2 = parseInt(dateArray2[1], 10) - 1;
      const date2 = dateArray2[2];
      const _entryDate2 = new Date(year2, month2, date2);


      setdateMax(new Date(_entryDate2));
      setEndDate(new Date(_entryDate2));

      let arreglo=[];


      if(data.data[0].pantallas==1){
          arreglo=[1];
      }else{
        for(let i=0;i<=data.data[0].pantallas;i++){
          arreglo.push(i);
        }
      }

     
      setNumeroDePantallas(arreglo);
      
      setImpresionesDiarias(data.data[0].total_impresiones_dia);

    } catch (err) {
      console.error(err);
    } 
  }

  async function fetchCampaignInfo () {  
    setLoadingRegistros(true);
    fetchImpresionesRegistros();
    fetchImpresionesRegistrosGenero();
    const fechainicio = new Intl.DateTimeFormat('es-MX', optionsDate).format(startDate);
    const yyyymmdd_Inicio = fechainicio.split('/').reverse().join('-');
    const fechafin = new Intl.DateTimeFormat('es-MX', optionsDate).format(endDate);
    const yyyymmdd_Final = fechafin.split('/').reverse().join('-');

    try {
        const response = await http.get('kpis/registros_impresiones_edad', {
        params: {
          id_camp: campaignId,
          inicio: yyyymmdd_Inicio,
          fin: yyyymmdd_Final,
          pantalla: selectedScreen,
        },
      });
      const { data } = response;


      setInfoKPI({
        //Male: data.data?.genero?.masculino,
        //Female: data.data?.genero?.femenino,
        edades: data.data,
        ageU18: data.data['-18'],
        age18: data.data['18+'],
        age25: data.data['25+'],
        age35: data.data['35+'],
        age45: data.data['45+'],
        age50: data.data['50+'],
        age55: data.data['55+'],
        ageU65: data.data['65+'],
      });

      setLoadingRegistros(false);
    } catch (err) {
      setInfoKPI(initialState);
    }
  }


  async function fetchImpresionesRegistros () {
    setLoadingImpresiones(true);
    const fechainicio = new Intl.DateTimeFormat('es-MX', optionsDate).format(startDate);
    const yyyymmdd_Inicio = fechainicio.split('/').reverse().join('-');
    const fechafin = new Intl.DateTimeFormat('es-MX', optionsDate).format(endDate);
    const yyyymmdd_Final = fechafin.split('/').reverse().join('-');

    try {
        const response = await http.get('kpis/registros_impresiones', {
        params: {
          id_camp: campaignId,
          inicio: yyyymmdd_Inicio,
          fin: yyyymmdd_Final,
          pantalla: selectedScreen,
        },
      });
      const { data } = response;
      
      setImpresiones(data.data);

      setLoadingImpresiones(false);
      } catch (err) {
      setImpresiones([]);

    }
  }




  async function fetchImpresionesRegistrosGenero () {
    setLoadingImpresionesGenero(true);
    const fechainicio = new Intl.DateTimeFormat('es-MX', optionsDate).format(startDate);
    const yyyymmdd_Inicio = fechainicio.split('/').reverse().join('-');
    const fechafin = new Intl.DateTimeFormat('es-MX', optionsDate).format(endDate);
    const yyyymmdd_Final = fechafin.split('/').reverse().join('-');

    try {
        const response = await http.get('kpis/registros_impresiones_genero', {
        params: {
          id_camp: campaignId,
          inicio: yyyymmdd_Inicio,
          fin: yyyymmdd_Final,
          pantalla: selectedScreen,
        },
      });
      const { data } = response;
      
      setImpresionesGenero(data.data);

      setLoadingImpresionesGenero(false);
      } catch (err) {
      setImpresionesGenero([]);

    }
  }




  async function consultar () {

    await fetchCampaignInfo();
   
  }


  const handleScreen = (event) => {
    setSelectedScreen(event.target.value);
  };




  useEffect(async () => {
    if (primerCarga) {
      setPrimerCarga(false);
   
      await fetchCampaignDate();
    }else{
      if(startDate && endDate && numeroDePantallas!=[] && impresionesDiarias!=0){
        await consultar();
      }
    }
  },[startDate,endDate,selectedScreen,numeroDePantallas,impresionesDiarias]);	
  

  return (
    <>
      <div className="columns">
        <div className="column" >
          <div className="card">
            <div className="card-content">
              <h4 className="title is-4">Buscar Registros</h4>
              <div className='columns'>

                <div className='column'>
                  <p className="has-text-weight-bold" style={styleTituloCard}>FECHAS DE INICIO</p>
                  <div className="field control has-icons-right">
                    <DatePicker
                      className='input'
                      selected={startDate}
                      onChange={handleStartDate}
                      minDate={dateMin}
                      maxDate={dateMax}
                      disabled={loadingRegistros ? true : false}
                    />
                    <span className="icon is-small is-right">
                      <FontAwesomeIcon icon={faCalendarDays} />
                    </span>
                  </div>
                </div>

                <div className='column'>
                  <p className="has-text-weight-bold" style={styleTituloCard}>FECHA DE FIN</p>
                  <div className="field control has-icons-right">
                    <DatePicker
                      className='input'
                      selected={endDate > dateMax ? dateMax : endDate}
                      onChange={handleEndDate}
                      selectsEnd
                      minDate={startDate}
                      maxDate={dateMax}
                      disabled={loadingRegistros ? true : false}
                    />
                    <span className="icon is-small is-right">
                      <FontAwesomeIcon icon={faCalendarDays} />
                    </span>
                  </div>
                </div>

                <div className="column">
                  <p className="has-text-weight-bold" style={styleTituloCard}>PANTALLA(S)</p>
                  <div className="select">
                  <select  value={selectedScreen} onChange={handleScreen} disabled={loadingRegistros ? true : false}>
                  
                    {numeroDePantallas.map((screen, index) => (

                      <option key={index} value={screen}>
                        {screen===0 ? "Todas" : "Pantalla " +screen }
                      </option>
                    ))}
                  </select>
                </div>
                  {/* <input className="input" type="date" value={searchByDate} onChange={dateOnChange} /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <KPI info={infoKPI} loading={loadingRegistros} impresiones={impresiones} loadingImpresiones={loadingImpresiones} impresionesGenero={impresionesGenero} loadingGenero={loadingImpresionesGenero} />

      <div className="card">
          <div className="card-content">
            <div className="table-container">
              {
                startDate && endDate?
                  <LogTable
                  campaignId={campaignId}
                 // tableInfo={tableInfo}
                  start={startDate }
                  end={endDate}
                  selectedScreen={selectedScreen}
                  totalRegistros={impresiones.impresiones}
                  /> : null
                }
            </div>
          </div>
        </div>                  
    </>
  );
}

export default Log;
