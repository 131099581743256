import React from 'react';
import { NavLink, useLocation  } from 'react-router-dom';

const styleNav = {
    borderRight: "1px solid rgb(219, 219, 219)",
}

function SubMenu({ data }) {

    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");

    return(
        <>
        { data.map( ( el, index ) => {
            return <li key={index} style={ data.length != index + 1 ? styleNav : {} } className={splitLocation[3] === el.location ? "is-active" : ""}><NavLink className={el.class} to={ el.path }>{ el.title }</NavLink></li>;
        }) }
        </>
    );
}

export default SubMenu;