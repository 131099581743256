import React from 'react';

function Missing() {
  return (
    <>
      ESTE COMPONENTE NO FUE ENCONTRADO 404!
    </>
  );
}

export default Missing;
