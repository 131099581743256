import React, { useState, useEffect } from 'react';
import {
  Chart as CharJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

CharJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function Days({ daysInfo, daysPercentage }) {
  const [chartInfo] = useState(daysInfo);
  const [chartData, setChartData] = useState({
    datasets: [],
  });
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    setChartData({
      labels: ['Días Calendario', 'Días Calendario Transcurridos','Días Efectivos', 'Días Efectivos Transcurridos'],	
      datasets: [
        {
          data: chartInfo,
          backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)','#fff5de', '#dbf2f2'],
          borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)','#ffce57', '#4bc0c0'],
          borderWidth: 2,
          datalabels: {
            color: '#999',
            anchor: 'end',
            align: 'top',
            formatter: (value) => {
              return  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
          },
        },
      ],
    });
    setChartOptions({
      responsive: true,
      plugins: {
        legend: {
          display: false,
          position: 'bottom',
        },
        

      },
      scales: {
        y: {
            beginAtZero: true,
            suggestedMin: 0,
            suggestedMax: Math.max(...chartInfo)+(Math.max(...chartInfo)*.1),
        }
      }
    });
  }, [chartInfo[0]]);

  if (!chartInfo[0]) {
    return <>Esta gráfica no está disponible por el momento ❌</>;
  }

  return (
    <>
      <Bar options={chartOptions} data={chartData} width={100} height={100} />
      <ul className="legends ml-0">
        <li>
          <small style={{ background: '#ffe0e6', border: '2px solid #fc7391' }} />
          <strong>{`Días Calendario: ${chartInfo[0]}`}</strong>
        </li>
        <li>
          <small style={{ background: '#d7ecfa', border: '2px solid #4dabea' }} />
          <strong>{`Días Calendario Transcurridos: ${chartInfo[1]} → ${daysPercentage}%`}</strong>
        </li>
        <li>
          <small style={{ background: '#fff5de', border: '2px solid #ffce57' }} />
          <strong>{`Días Efectivos: ${chartInfo[2]}`}</strong>
        </li>
        <li>
          <small style={{ background: '#dbf2f2', border: '2px solid #4bc0c0' }} />
          <strong>{`Días Efectivos Transcurridos: ${chartInfo[3]}`}</strong>
        </li>
      </ul>
    </>
  );
}

export default Days;
