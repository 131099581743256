// React
import React, { useEffect, useState } from 'react';

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import useMediaQuery from '@mui/material/useMediaQuery';
import { faEject } from '@fortawesome/free-solid-svg-icons';

ChartJS.register( CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

function GraphLine(dataGantt) {

 
  let campanas = dataGantt.dataGantt.campanas;

  let labels = campanas.map((item) => item.id_camp+" "+item.nombre.slice(0, 6).toUpperCase());

  const isMobile = useMediaQuery('(max-width:890px)');

  let datos=[];

  campanas.map((item) => {
    //console.log(item);
    datos.push(
      {
        data: [
          {x: item.SemanaInicio, y: item.id_camp+" "+item.nombre.slice(0, 6).toUpperCase()},
          {x: item.SemanaFin, y: item.id_camp+" "+item.nombre.slice(0, 6).toUpperCase()}
        ],
        borderColor: 'rgb(204, 204, 204)',
        backgroundColor: 'rgba(204, 204, 204, 0.3)',
        borderWidth: 5,
        datalabels: {
          display: false
        },
      }
    )
  });

  const data = {
    labels,
    datasets: datos
  };

  const options = {
    aspectRatio: isMobile ? 5 : campanas.length > 10 ? 1 :  campanas.length < 5 ? 12: 5,
    responsive:true,
    indexAxis: 'y',
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {},
      tooltip: {
        callbacks: {
          title: data => {
            //console.log(data);
            let title = '';
            let fecha;
            const { dataIndex } = data[0];
            dataIndex == 0 ? title = 'Fecha inicio' : title = 'Fecha fin';
            let campana=campanas.filter((item) => item.id_camp+" "+item.nombre.slice(0, 6).toUpperCase() == data[0].label);
            

            dataIndex == 0 ? fecha = new Date(campana[0].inicio).toLocaleDateString() : fecha = new Date(campana[0].fin).toLocaleDateString();


            
            return `${title.toLocaleUpperCase()}\n${fecha}`;
            

          },
          label: data => {

            let date = new Date();
            var day = new Date(date.getFullYear(), 0, (data.formattedValue) * 7 + 1);
            var month = new Date(date.getFullYear(), 0, day.getDate());
            // console.log(month);

            return ``;
            return `${day.getDate()}/${month.getMonth() + 1}/${date.getFullYear()}`;

            

           // console.log();

            // let date = new Date().getWeekNumber();
            // console.log(date);

            let fecha=new Date(2008,0,1);
            let primerDiaDelAno=fecha.getDay();
            let fecha2=new Date(2008,1,(6+7-primerDiaDelAno));
            let tiempopasado=fecha2-fecha;
            let semanas=Math.floor(tiempopasado/1000/60/60/24/7);
            if(semanas==0){semanas=52}
            alert(semanas)
            // saberSemana(6,1,2008)

            return "Critical"
          },
        },
        backgroundColor: 'rgb(255, 255, 255)',
        titleColor: 'rgb(153, 153, 153)',
        borderColor: 'rgb(153, 153, 153)',
        borderWidth: 1,
        cornerRadius: 0,
        boxPadding: 0,
        titleMarginBottom: 0,
        displayColors: false,
      }
    },
    scales: {
      x:{
        max: 52.3,
        min: campanas.length > 5 ? 0.3 : -0.3,
        ticks: {
          stepSize: 1,
          display: false,
        },
        grid: {
          borderWidth: 0,
          drawOnChartArea: false,
          drawTicks: false,
        },
      },
      y: {

        grid: {
          borderWidth: 0,
          drawOnChartArea: false,
          drawTicks: false,
        },
        ticks: {
          crossAlign: 'far',
          color: 'rgb(153, 153, 153)',
          font: {
              //weight: 'bold',
              size: 10,
          }
        },
      },
    },
  }

  return <Line options={options} data={data} />;
}

export default GraphLine