import React from 'react';

function Payments() {
  return (
    <div>
      Aquí se está renderizando PAGOS
    </div>
  );
}

export default Payments;
