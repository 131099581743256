import React from 'react';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import ScreenOne from './ScreenOne';
import ScreenTwo from './ScreenTwo';
import ScreenThree from './ScreenThree';
import ScreenFour from './ScreenFour';
import AnalyticsDownload from '../../iconComponents/AnalyticsDownload';
import HorizontalLogo from '../../../icons/HorizontalLogo.png';
import { useAuth } from '../../../contexts/auth';


function ScreenBox({
  screenData,
}) {

  const {
    auth,
  } = useAuth();


  const styleTituloCard = {
    color: '#999',
    fontSize: '18px',
    marginBottom: '20px',
    fontWeight: 'bold',

  };
  // De la línea 24 a 38 se establece una función para crear un archivo PDF con las gráficas
  // de este mismo componente.
  const printRef = React.useRef();
  const handleDownloadPDF = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL('image/png');

    // eslint-disable-next-line new-cap
    const pdf = new jsPDF('landscape', 'pt', [511, 510]);
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
    pdf.save('Pantallas.pdf');
  };

  if (!screenData) {
    return <div className="campaign-total-impressions box">Esta sección no se encuentra disponible por el momento.</div>;
  }
  return (
    <>
      <div className="columns" ref={printRef}>

        {
          screenData.map((screen,key) => (
          ((key === screenData.length-1  && screen.valores.numero_concesiones===0) ||  (key === screenData.length-1  && auth.tipo!="ADMIN")) ? 
            null:
            key <3 ? (
            <div key={key} className="column is-one-third">
              <p style={styleTituloCard}>PANTALLA {key+1}</p>
                
                <ScreenOne screenData={screen} concesion={
                  screen.valores.numero_concesiones>0? true:false
                } 
                
                porcentajeConcesion={
                  screen.valores.numero_concesiones>0? ((screen.valores.numero_concesiones/screenData[key-1].valores.impresiones)*100).toFixed(2):0
                }
                
                /> 
            </div>
          ):null))
        } 


    
        {/* <img className="print-logo" src={HorizontalLogo} alt="Logo horizontal" /> */}
      </div>
      <div>
        {/* <button className="button download" type="button" onClick={handleDownloadPDF}>
          <AnalyticsDownload />
          Exportar
        </button> */}
      </div>
    </>
  );
}

export default ScreenBox;
