// React
import React, { useState } from 'react';

//Colors
import { Gray999 } from '../../helpers/colors';

// Grafica
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useEffect } from 'react';

ChartJS.register( CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend );

const styleContentTitleTable = {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    width: '76%',
}

const styleTitleTable = {
    fontSize: 14,
    fontWeight: 500,
    color: Gray999,
}

function HorizontalBar2({ tableTitle, arrayData, labels }) {

    if (!arrayData[0])
        return <>Esta gráfica no está disponible por el momento ❌</>;

    const totalData = arrayData[8];

    const [leftData, setLeftData] = useState ([]);
    const [rightData, setRightData] = useState ([]);
    const [customLabels, setCustomLabels] = useState ([]);
    const [screenWidth, setScreenWidth] = useState(screen.width);

    useEffect(() => {

        arrayData.map( (data, index) => {

            if (++index != arrayData.length) {
                setCustomLabels(customLabels => [...customLabels, `${(data * 100 / totalData).toFixed(1)}%`]);
                setLeftData(leftData => [...leftData, `${(data * 100 / totalData).toFixed(1) * -1}`]);
                setRightData(rightData => [...rightData, `${(data * 100 / totalData).toFixed(1)}`]);
            }
    
        });

    }, [])

    const calcPaddingScreen = () => {

        window.addEventListener("resize", e => {
            setScreenWidth(e.target.window.screen.width);
        });

        if (screenWidth < 769 && screenWidth > 475) {
            return 30;   
        }
        if (screenWidth < 475) {
            return 5;   
        }

        return 10;

    }

    const data = {
        labels: customLabels,
        datasets: [{
            data: leftData,
            datalabels: {
                display: false
            },
            borderWidth: 2,
            backgroundColor: [ 'rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(255, 206, 86, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(153, 102, 255, 0.2)', 'rgba(255, 159, 64, 0.2)' ],
            borderColor: [ 'rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)', 'rgba(75, 192, 192, 1)', 'rgba(153, 102, 255, 1)', 'rgba(255, 159, 64, 1)' ],
            barPercentage: 0.5,
        },
        {
            data: rightData,
            datalabels: {
                anchor: 'start',
                align: 'top',
                font: {
                    weight: 'bold',
                    size: 14,
                },
            },
            borderWidth: 2,
            backgroundColor: [ 'rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(255, 206, 86, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(153, 102, 255, 0.2)', 'rgba(255, 159, 64, 0.2)' ],
            borderColor: [ 'rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)', 'rgba(75, 192, 192, 1)', 'rgba(153, 102, 255, 1)', 'rgba(255, 159, 64, 1)' ],
            barPercentage: 0.5,
        },
        ]
    }

    const options = {
        aspectRatio: .8,
        responsive: true,
        indexAxis: 'y',
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text:  `100 %`,
                color: Gray999,
                font: {
                    weight: '400',
                    size: 14,
                },
                padding: {
                    bottom: 8,
                    top: 3,
                },
                align: 'end',
            },
            datalabels: {
                padding: {
                    bottom: calcPaddingScreen(),
                },
                formatter: (value, el) => {

                    const { dataIndex } = el;
    
                    return `${labels[dataIndex]}`;
                }
            },
            tooltip: {
                callbacks: {
                    label: data => {
                        return '';
                    },
                }
            }
        },
        scales: {
            right: {
                axis: 'y',
                position: 'right',
                stacked: true,
                ticks: {
                    crossAlign: 'far',
                    color: Gray999,
                    font: {
                        weight: '400',
                        size: 14,
                    },
                },
                grid: {
                    borderWidth: 0,
                    drawOnChartArea: false,
                    drawTicks: false,
                },
            },
            buttom: {
                axis: 'x',
                grid: {
                    lineWidth: 0,
                    borderWidth: 0,
                    drawOnChartArea: false,
                    drawTicks: false,
                },
                ticks: {
                    display: false,
                },
            }
        }
    }
    
    const { borderColor, backgroundColor } = data.datasets[0];

    return (
        <>
        <div style={styleContentTitleTable}>
            <span style={styleTitleTable} >{ tableTitle }</span>
        </div>
        <Bar options={options} data={data} />
        <ul className="legends ml-0">
        { data.labels.map((label, index) => {
            return (
                <>
                <li key={index}>
                    <small style={{ background: backgroundColor[index] == undefined ? backgroundColor[index - 6] : backgroundColor[index], border: `2px solid ${borderColor[index] == undefined ? borderColor[index - 6] : borderColor[index]}` }} />
                    <strong>{`${labels[index]}: ${label}`}</strong>
                </li>
                </>
            );
        })}
        </ul>
        </>
    )
}

export default HorizontalBar2;