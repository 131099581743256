import React, { useState, useEffect } from 'react';
import { NavLink, Outlet, useNavigate,Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import CampaignsIcon from '../iconComponents/CampaignsIcon';
import DashboardIcon from '../iconComponents/DashboardIcon';
import AccountIcon from '../iconComponents/AccountIcon';
import Logo from '../../icons/logo-full-green-horizontal.svg';

// Icons
import {FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons'

function NavBar() {

  const auth = useAuth();

  const [isMobile, setIsMobile] = useState(false);
  const [screenWidth, setScreenWidth] = useState(screen.width);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {

    if (screenWidth < 1024) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }

  }, [screenWidth]);

  window.addEventListener("resize", e => {
    setScreenWidth(e.target.window.screen.width);
  });


  const avatar = {
    height: '35px',
    width: '35px',
    backgroundColor: '#70d44b',
    color: '#fff',
    fontWeight: 'bold',
    borderRadius: '50%',
    display: 'flex',
    alignitems: 'center',
    justifycontent: 'center',
  };

  function handleLogout() {
    auth.logout();
    navigate('/');
  }

  function handleOpenMenu() {
    document.querySelector('.navbar-burger').classList.toggle('is-active');
    document.querySelector('.navbar-menu').classList.toggle('is-active');
  }

  function handleOpenSesion() {
    document.querySelector('#sesion').classList.toggle('is-active');
  }

  return (
    <>
      <nav className="navbar has-centered-menu"  role="navigation" aria-label="main navigation" >
        <div className="container" style={{paddingRight:"20px",paddingLeft:"20px"}}>
        <div className="navbar-brand m-0">
          <a className="navbar-item p-0" href="/">
            <img src={Logo} alt="wiwi" width="95" />
          </a>
          <a role="button" className="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample" tabIndex={0} onKeyDown={handleOpenMenu} onClick={handleOpenMenu}>
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </a>
        </div>


        <div id="navbarBasicExample" className="navbar-menu">
          <div className="navbar-start">
            <NavLink to="/dashboard" className={({ isActive }) => (isActive || location.pathname == '/' ? 'navbar-item is-active' : 'navbar-item')}>
              <DashboardIcon />
              <span className="has-text-weight-bold">Dashboard</span>
            </NavLink>
            <NavLink to="/campaigns" className={({ isActive }) => (isActive ? 'navbar-item is-active' : 'navbar-item')}>
              <CampaignsIcon />
              <span className="has-text-weight-bold">Campañas</span>
            </NavLink>
            <NavLink className={({ isActive }) => (isActive ? 'navbar-item is-active' : 'navbar-item')} to="/account"  >
              <AccountIcon />
              <span className="has-text-weight-bold">Cuenta</span>
            </NavLink>
            { !isMobile ?
              <></>
              :
              <a role="button" className="navbar-item" onClick={handleLogout} onKeyDown={handleLogout} tabIndex={0}>
                <FontAwesomeIcon icon={faRightFromBracket} />
                Cerrar Sesión
              </a>
            }
          </div>

          { isMobile ?
            <></>
            :
            <div className="navbar-end">
              <div className="navbar-item has-dropdown arrow-less" id="sesion">
                <a role="button" className="navbar-link" onClick={handleOpenSesion} onKeyDown={handleOpenSesion} tabIndex={0}>
                  <span className="icon" style={avatar}>
                    {auth.auth.name.slice(0, 1)}
                  </span>
                </a>
                <div className="navbar-dropdown is-right">
                  <a className="navbar-item" href="/account">
                    Cuenta
                  </a>
                  <hr className="navbar-divider" />
                  <a role="button" className="navbar-item" onClick={handleLogout} onKeyDown={handleLogout} tabIndex={0}>
                    Cerrar Sesión
                  </a>
                </div>
              </div>
            </div> 
            }
          </div>
        </div>
      </nav>
      <Outlet />
    </>
  );
}

export default NavBar;
