// React
import React, { useEffect, useState } from 'react';

// Style
import { styleTituloCard, estyleElementList, estyleElementListFinal } from '../../helpers/globals';

// Icon
import {FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowLeft, faCircleArrowRight } from '@fortawesome/free-solid-svg-icons'


function CardMedium({ title, data, cardHeight, styleCardTitulo, titleClass, isAdjuntar, dataPDFds }) {

    const [idPDF, setIdPDF] = useState(1)
    const [PDFs, setPDFs] = useState([])

    const styleCard = {
        height: `${cardHeight}%`
    };

    const viewPDF = () => {
        const modal = document.getElementById('modal-componet');
        modal.classList.add('is-active');
    }

    const closeModal = () => {
        const modal = document.getElementById('modal-componet');
        modal.classList.remove('is-active');
    }

    useEffect(() => {

        const PDFs = [];

        if (dataPDFds != undefined) {

            dataPDFds.forEach(pdf => {
                PDFs.push(<iframe src={`https://wiwi.cloudmarketingmx.com${pdf.replace('PDFs', 'PDFs/')}`} width='100%' height='700px'></iframe>)
            });
    
            setPDFs(PDFs)

        }

    }, [])

    return (
        <>
        <div className="card" id='informacion' style={styleCard}>
            <div className="card-content">
                <p className={`has-text-weight-bold ${titleClass}`} style={ styleCardTitulo == undefined ? styleTituloCard : styleCardTitulo }>{ title }</p>

                <ul>
                    { data.map((resp, index) => {
                        return(
                        <li style={ data.length != index + 1 || (isAdjuntar != false) ? estyleElementList : estyleElementListFinal} key={ index }>
                            <div className="columns is-mobile">
                                <div className="column is-half">
                                    <small>{resp.name}</small>
                                </div>
                                <div className="column is-half has-text-right">
                                    <strong>{resp.value}</strong>
                                </div>
                            </div>
                        </li>
                        );
                    }) }
                    { isAdjuntar != false ?
                    <li style={estyleElementListFinal}>
                        <div className="columns is-mobile">
                            <div className="column is-half">
                                <small>Adjuntos:</small>
                            </div>
                            <div className="column is-half has-text-right">
                                <strong className='cursor-pointer view-files' onClick={viewPDF}>Ver Adjuntos</strong>
                            </div>
                        </div>
                    </li> :
                    ''
                    }
                </ul>

            </div>
        </div>
        <div className="modal" id='modal-componet'>
            <div className="modal-background" onClick={closeModal} style={{backgroundColor:'transparent'}}></div>
            <div className="modal-card">
                <header className="modal-card-head">
                    <div className='modal-card-title is-flex is-align-items-center'>
                        <FontAwesomeIcon style={{color:'rgba(112, 212, 75, 1)'}} onClick={ () => { setIdPDF(idPDF - 1) }} icon={faCircleArrowLeft} className={`cursor-pointer mr-3 ${ idPDF == 1 ? 'disabled' : '' }`} />
                        <span style={{ fontSize: '16px' }}> {idPDF} / {PDFs.length}</span>
                        <FontAwesomeIcon style={{color:'rgba(112, 212, 75, 1)'}} onClick={ () => { setIdPDF(idPDF + 1) }} icon={faCircleArrowRight} className={`cursor-pointer ml-3 ${ idPDF == PDFs.length ? 'disabled' : '' }`} />
                    </div>

                    <button style={{backgroundColor:'rgba(112, 212, 75, 1)'}} onClick={closeModal } className="delete" aria-label="close"></button>
                </header>

                <section className="modal-card-body">
                    { PDFs.slice(idPDF - 1, idPDF) }
                </section>
            
            </div>
        </div>
        </>
    );
}

export default CardMedium;