// React
import React from 'react';

// Style
import { styleTituloCard } from '../../helpers/globals';

// Grafica
import { Chart as CharJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import Spinner from '../Spinner/Spinner';


CharJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);

function CardGraphPie({ title, chartOptions, chartData,isLoading }) {

    const styleCard = {
        height: '100%'
    };

    const { borderColor, backgroundColor,data } = chartData.datasets[0]


    return (
        <>
        <div className="card" style={styleCard}>
            <div className="card-content">
                <p className="has-text-weight-bold" style={styleTituloCard}>{ title.toUpperCase() }</p>

                { isLoading ? <Spinner /> :<>
                <Pie options={chartOptions} data={chartData} />

                <ul className="legends ml-0">

                    {chartData.labels.map((label, index) => {
                        return(
                            <li key={index}>
                                <small style={{ background: backgroundColor[index], border: `2px solid ${borderColor[index]}` }} />
                                <strong>{`${label}: ${data[index]}`}</strong>
                            </li>
                        );
                    })}

                </ul>
                </>}
            </div>
        </div>
        </>
    );
}

export default CardGraphPie;