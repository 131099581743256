import React from 'react';

function ReturnImage() {
  return (
    <small>
      <svg viewBox="0 0 50 50">
        <path
          d="M47.002,3.786c-1.607,0-2.91,1.304-2.91,2.912v8.491c0,11.217-5.379,13.345-15.305,13.345H10.31l6.904-6.743
                c1.15-1.123,1.171-2.967,0.046-4.117c-1.122-1.152-2.965-1.171-4.116-0.048L0.964,29.521c-0.562,0.549-0.879,1.305-0.877,2.09
                c0.002,0.789,0.322,1.541,0.887,2.086l12.101,11.697c0.566,0.547,1.295,0.82,2.024,0.82c0.762,0,1.522-0.299,2.094-0.889
                c1.117-1.156,1.086-3-0.069-4.117l-7.09-6.854h18.754c9.836,0,21.127-2.18,21.127-19.167V6.698
                C49.914,5.089,48.611,3.786,47.002,3.786z"
          stroke="currentColor"
        />
      </svg>
    </small>
  );
}

export default ReturnImage;
