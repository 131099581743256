import React from 'react';
import './Pages.css';
import { estiloLista,estiloElementoLista } from '../../../helpers/globals';

import {FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

export default function Pages({ logsPerPage, allLogs, pages , currentPage,loading}) {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(allLogs / logsPerPage); i += 1) {
    pageNumbers.push(i);
    
  }

  
  const dibujarNumeroPagina = (number,key) => {
    
    let dibujar = false;
    let dibujarPuntosAntes = false;
    let dibujarPuntosDespues = false;
    
    if(pageNumbers.length > 1) {
        if(number === 1) {
          dibujar = true;
        }
        
        if(number < 4 && currentPage < 4) {
          dibujar = true;
        }
        if(number===currentPage-1) {
          dibujar = true;
          if(number >=5) {
            dibujarPuntosAntes=true;
          }
        }



        if(number===currentPage+1 || (number===3 && currentPage<3) ) {

           dibujar = true;
          if(number >=3 && currentPage<number) {
            dibujarPuntosDespues=true;
          }
        
        }

        if(number===currentPage) {
          dibujar = true;
        }

        if(number === pageNumbers.length) {
          dibujar = true;
        }
      
    }

    if (dibujar) {
     
        return (
          dibujarPuntosAntes ? (
            <>
            <li key={(key*1000)}><span key={key*1001} className="pagination-ellipsis">&hellip;</span></li>
             <li key={key}>
              <a
                className={number === currentPage ? 'pagination-link is-current' : 'pagination-link'}
                aria-label={`Goto page ${number}`}
                onClick={() => pages(number)}
              >
              {number}
            </a>
          </li>
            </>
          )
          
          : dibujarPuntosDespues ? (
            <>
             <li key={key}>
            <a
              className={number === currentPage ? 'pagination-link is-current' : 'pagination-link'}
              aria-label={`Goto page ${number}`}
              onClick={() => pages(number)}
            >
              {number}
            </a>
          </li>

            <li key={(key*1000)}><span key={key*1001} className="pagination-ellipsis">&hellip;</span></li>
            </>
          ): (
            <li key={key}>
            <a
              className={number === currentPage ? 'pagination-link is-current' : 'pagination-link'}
              aria-label={`Goto page ${number}`}
              onClick={() => pages(number)}
            >
              {number}
            </a>
          </li>

         
         
        )
      );
    }
  }
  return (
    <nav className="pagination is-size-7-mobile" role="navigation" aria-label="pagination">
      
      <div className='is-hidden-tablet'>
        <a className={ currentPage==1 || loading ? 'pagination-previous is-disabled' : 'pagination-previous'} onClick={ () => currentPage > 1 && loading==false ? pages(currentPage - 1) : null } ><FontAwesomeIcon icon={faArrowLeft} /></a>
      </div>
      <a className={ currentPage==1 || loading ? 'pagination-previous is-hidden-mobile is-disabled' : 'pagination-previous is-hidden-mobile'} onClick={ () => currentPage > 1 && loading==false ? pages(currentPage - 1) : null } >Anterior</a>

        <ul className="pagination-list" style={estiloLista}>
          {pageNumbers.map((number,i) => dibujarNumeroPagina(number,i))}
        </ul>

      <div className='is-hidden-tablet'>
        <a className={ currentPage==pageNumbers.length || loading ? 'pagination-next is-disabled' : 'pagination-next'} onClick={ () => currentPage < pageNumbers.length && loading==false ? pages(currentPage + 1) : null }><FontAwesomeIcon icon={faArrowRight} /></a>
      </div>
      <a className={ currentPage==pageNumbers.length || loading ? 'pagination-next is-hidden-mobile is-disabled' : 'pagination-next is-hidden-mobile'} onClick={ () => currentPage < pageNumbers.length && loading==false ? pages(currentPage + 1) : null }>Siguiente</a>
    </nav>
  );
}
