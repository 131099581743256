import React, { useState, useEffect } from 'react';
import {
  Chart as CharJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

CharJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function Device({ deviceNameInfo, deviceValueInfo }) {
  const [chartLabels] = useState(deviceNameInfo);
  const [chartInfo] = useState(deviceValueInfo);
  const [chartData, setChartData] = useState({
    datasets: [],
  });

  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    setChartData({
      labels: chartLabels,
      datasets: [
        {
          data: chartInfo,
          backgroundColor: ['rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)'],

          borderColor: ['rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)'],
          borderWidth: 2,
          indexAxis: 'y',
          datalabels: {
            color: '#999',
            anchor: 'end',
            align: 'end',
          },
        },
      ],
    });
    setChartOptions({
      responsive: true,
      plugins: {
        legend: {
          display: false,
          position: 'bottom',
        },
      },
      indexAxis: 'y',
        scales: {
            x: {
                beginAtZero: true,
                suggestedMin: 0,
                suggestedMax: Math.max(...chartInfo)+(Math.max(...chartInfo)*.1),
            }
        }
      }
    );
  }, [chartInfo]);

  if (!chartInfo[0]) {
    return <>Esta gráfica no está disponible por el momento ❌</>;
  }

  return (
    <>
      <Bar options={chartOptions} data={chartData} width={100} height={100} />
      <ul className="legends ml-0">
        <li>
          <small style={{ background: 'rgba(255, 99, 132, 0.2)', border: '2px solid rgba(255, 99, 132, 1)' }} />
          <strong>{`${chartLabels[0]}: ${chartInfo[0]} %`}</strong>
        </li>
        <li>
          <small style={{ background: 'rgba(54, 162, 235, 0.2)', border: '2px solid rgba(54, 162, 235, 1)' }} />
          <strong>{`${chartLabels[1]}: ${chartInfo[1]} %`}</strong>
        </li>
        <li>
          <small style={{ background: 'rgba(255, 206, 86, 0.2)', border: '2px solid rgba(255, 206, 86, 1)' }} />
          <strong>{`${chartLabels[2]}: ${chartInfo[2]} %`}</strong>
        </li>
        <li>
          <small style={{ background: 'rgba(75, 192, 192, 0.2)', border: '2px solid rgba(75, 192, 192, 1)' }} />
          <strong>{`${chartLabels[3]}: ${chartInfo[3]} %`}</strong>
        </li>
        <li>
          <small style={{ background: 'rgba(153, 102, 255, 0.2)', border: '2px solid rgba(153, 102, 255, 1)' }} />
          <strong>{`${chartLabels[4]}: ${chartInfo[4]} %`}</strong>
        </li>
        <li>
          <small style={{ background: 'rgba(255, 159, 64, 0.2)', border: '2px solid rgba(255, 159, 64, 1)' }} />
          <strong>{`${chartLabels[5]}: ${chartInfo[5]} %`}</strong>
        </li>
        <li>
          <small style={{ background: 'rgba(255, 99, 132, 0.2)', border: '2px solid rgba(255, 99, 132, 1)' }} />
          <strong>{`${chartLabels[6]}: ${chartInfo[6]} %`}</strong>
        </li>
        <li>
          <small style={{ background: 'rgba(54, 162, 235, 0.2)', border: '2px solid rgba(54, 162, 235, 1)' }} />
          <strong>{`${chartLabels[7]}: ${chartInfo[7]} %`}</strong>
        </li>
        {
          chartInfo.length > 8 ? (
            <li>
              <small style={{ background: 'rgba(255, 206, 86, 0.2)', border: '2px solid rgba(255, 206, 86, 1)' }} />
              <strong>{`${chartLabels[8]}: ${chartInfo[8]} %`}</strong>
            </li>
          )
            : (
              <>
              </>
            )
        }
        {
          chartInfo.length > 9 ? (
            <li>
              <small style={{ background: 'rgba(75, 192, 192, 0.2)', border: '2px solid rgba(75, 192, 192, 1)' }} />
              <strong>{`${chartLabels[9]}: ${chartInfo[9]} %`}</strong>
            </li>
          )
            : (
              <>
              </>
            )
        }
      </ul>
    </>
  );
}

export default Device;
