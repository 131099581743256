export const estiloLista = {
  listStyleType: 'none',
  marginTop: '0',
  marginBottom: '0',
  marginLeft: '0',
 
};

export const estiloElementoLista = {
  marginTop: '0',
  marginBottom: '5px',
  padding: '5px 0px 10px'
};


export const styleTituloCard = {
  color: '#999',
  fontSize: '14px',
  marginBottom: '15px',
  textTransform: 'uppercase',
};

export const estyleElementList = {
  marginTop: '0',
  borderBottom: "1px solid #dbdbdb",
  padding: '5px 0px 5px'
};

export const estyleElementListFinal = {
  marginTop: '0',
  padding: '5px 0px 5px'
};