import React, { useState, useEffect } from 'react';
import {
  Chart as CharJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

CharJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function ScreenTwo({ screenData, ctr2 }) {
  const titles = screenData?.map((el) => el.titulo);
  const values = screenData?.map((el) => el.valor);

  const [chartData, setChartData] = useState({
    datasets: [],
  });

  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    setChartData({
      labels: titles,
      datasets: [
        {
          data: values,
          backgroundColor: ['#ffe0e6', 'rgb(215 236 250)', '#fff5de', '#dbf2f2', '#ebe0ff'],
          borderColor: ['#fc7391', 'rgb(135 199 243)', '#ffce57', '#4bc0c0', '#9966ff'],
          borderWidth: 2,
          borderRadius: 0,
          datalabels: {
            color: '#999',
            anchor: 'end',
            align: 'top',
            formatter: (value) => {
              return  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
          },
        },
      ],
    });
    setChartOptions({
      responsive: true,
      scales: {
        x: {
          ticks: {
            display: false,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
          position: 'bottom',
        },
      },
    });
  }, [values[0]]);

  if (!screenData[0]) {
    return <>Esta gráfica no está disponible por el momento ❌</>;
  }

  return (
    <>
      <Bar options={chartOptions} data={chartData} width={100} height={100} />
      <ul className="legends">
        <li>
          <small style={{ background: '#ffe0e6', border: '2px solid #fc7391' }} />
          <strong>{`${titles[0]}: ${values[0]}`}</strong>
        </li>
        {
        values.length > 1 ? (
          <li>
            <small style={{ background: 'rgb(215 236 250)', border: '2px solid rgb(135 199 243)' }} />
            <strong>{`${titles[1]}: ${values[1]}`}</strong>
          </li>
        )
          : <div />
        }
        {
        values.length > 2 ? (
          <li>
            <small style={{ background: '#fff5de', border: '2px solid #ffce57' }} />
            <strong>{`${titles[2]}: ${values[2]}`}</strong>
          </li>
        )
          : <div />
        }
        {
        values.length > 3 ? (
          <li>
            <small style={{ background: '#dbf2f2', border: '2px solid #4bc0c0' }} />
            <strong>{`${titles[3]}: ${values[3]}`}</strong>
          </li>
        )
          : <div />
        }
        {
        values.length > 4 ? (
          <li>
            <small style={{ background: '#ebe0ff', border: '2px solid #9966ff' }} />
            <strong>{`${titles[4]}: ${values[4]}`}</strong>
          </li>
        )
          : <div />
        }
        {
            ctr2 === 0 ? <div />
              : (
                <li>
                  <small style={{ background: 'rgba(112,212,75,0.25)', border: '2px solid rgba(112,212,75,1)' }} />
                  <strong style={{ fontWeight: 'bolder' }}>{`CTR: ${ctr2}`}</strong>
                </li>
              )
        }
      </ul>
    </>
  );
}

export default ScreenTwo;
