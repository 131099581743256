import React, { useMemo } from 'react';
import './Spinner.css';
import PuffLoader from "react-spinners/ClipLoader";

// Este es un spinner hecho totalmente con HTML y CSS, cuenta con 12 frames.
// Inicialmente se hizo como solución temporal y por eso se uso styles components.

function Spinner() {
  const dots = useMemo(() => {
    const qty = 12;

    const em = [];

    for (let x = 0; x < qty; x += 1) {
      em.push(<div key={`spinner_dot_${x}`} />);
    }

    return em;
  }, []);

  return (
    <div className="center">
      <PuffLoader
        color="#64c54c"
        size={40}
      />

      {/* <div className="lds-spinner">
        {dots}
      </div> */}
    </div>
  );
}

export default Spinner;
