// React
import React, { useEffect, useState } from 'react';

//Colors
import { Gray999 } from '../../helpers/colors';

// Grafica
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register( CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels );

function HorizontalBar({ tableTitle, leftData, rightData, labels }) {
    
    const arrayRight = rightData;

    const [arrayLeft, setArrayLeft] = useState([]);
    const [maxValue, setMaxValue] = useState(0);
    const [minValue, setMinValue] = useState(0);
    const [totalValueLeft, setTotalValueLeft] = useState(0);
    const [totalValueRight, setTotalValueRight] = useState(0);

    useEffect(() => {

        let auxMaxValue = 0;
        let auxTotalValueLeft = 0;
        let auxTotalValueRight = 0;
        let auxTotal = 0;

        Math.max(...leftData) > Math.max(...rightData) ? auxMaxValue = Math.max(...leftData) + Math.max(...leftData) * 0.6 : auxMaxValue = Math.max(...rightData) + Math.max(...leftData) * 0.6;
        setMaxValue(auxMaxValue);
        setMinValue(auxMaxValue * -1);

        auxTotalValueLeft = leftData.reduce((previousValue, currentValue) => { return parseInt(previousValue) + parseInt(currentValue) });
        auxTotalValueRight = rightData.reduce((previousValue, currentValue) => { return parseInt(previousValue) + parseInt(currentValue) });
        auxTotal = auxTotalValueLeft + auxTotalValueRight
        setTotalValueLeft(((auxTotalValueLeft * 100) / auxTotal).toFixed(1));
        setTotalValueRight(((auxTotalValueRight * 100) / auxTotal).toFixed(1));

        leftData.map(el => setArrayLeft(arrayLeft => [...arrayLeft, el * -1]));

        // console.log(leftData);

    }, [])

    // console.log(arrayLeft);
    // console.log(arrayRight);

    const data = {
        labels,
        datasets: [{
            data: arrayLeft,
            yAxisID: 'left',
            datalabels: {
                anchor: 'start',
                align: 'left',
                color: Gray999,
                font: {
                    weight: '500',
                    size: 14,
                },
            },
            borderWidth: 2,
            backgroundColor: [ 'rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(255, 206, 86, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(153, 102, 255, 0.2)', 'rgba(255, 159, 64, 0.2)' ],
            borderColor: [ 'rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)', 'rgba(75, 192, 192, 1)', 'rgba(153, 102, 255, 1)', 'rgba(255, 159, 64, 1)' ],
        },
        {
            data: arrayRight,
            yAxisID: 'right',
            datalabels: {
                anchor: 'end',
                align: 'right',
                color: Gray999,
                font: {
                    weight: '500',
                    size: 14,
                },
            },
            borderWidth: 2,
            backgroundColor: [ 'rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(255, 206, 86, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(153, 102, 255, 0.2)', 'rgba(255, 159, 64, 0.2)' ],
            borderColor: [ 'rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)', 'rgba(75, 192, 192, 1)', 'rgba(153, 102, 255, 1)', 'rgba(255, 159, 64, 1)' ],
        }]
    };

    const options = {
        responsive: true,
        aspectRatio: 1.5,
        indexAxis: 'y',
        plugins: {
            legend: {
                display: false,
            },
            datalabels: {
                formatter: value => {
    
                    if (value < 0)
                        value = value * -1
    
                    return `${value} %`;
                }
            },
            title: {
                display: true,
                text:  ` ${totalValueLeft} %   ${tableTitle}   ${totalValueRight} %`,
                color: Gray999,
                font: {
                    weight: '500',
                    size: 14,
                },
                padding: {
                    bottom: 5,
                }
            },
        },
        scales: {
            left: {
                axis: 'y',
                labels: arrayLeft,
                stacked: true,
                ticks: {
                    display: false,
                },
                grid: {
                    borderWidth: 0,
                    drawOnChartArea: false,
                    drawTicks: false,
                },
            },
            right: {
                axis: 'y',
                labels: arrayRight,
                position: 'right',
                ticks: {
                    display: false,
                },
                grid: {
                    borderWidth: 0,
                    drawOnChartArea: false,
                    drawTicks: false,
                },
            },
            buttom: {
                axis: 'x',
                beginAtZero: true,
                suggestedMin: minValue,
                suggestedMax: maxValue,
                ticks: {
                    display: false,
                },
                grid: {
                    lineWidth: 5,
                    borderWidth: 0,
                    drawOnChartArea: false,
                    drawTicks: false,
                },
            }
        },
    };

    const { borderColor, backgroundColor } = data.datasets[0];

    return (
        <>
        <Bar options={options} data={data} />

        <ul className="legends col2 ml-0">
        { data.labels.map((label, index) => {
            return (
                <>
                <li key={index}>
                    <small style={{ background: backgroundColor[index] == undefined ? backgroundColor[index - 6] : backgroundColor[index], border: `2px solid ${borderColor[index] == undefined ? borderColor[index - 6] : borderColor[index]}` }} />
                    <strong>{`${label}: ${Math.abs(arrayLeft[index])} %`}</strong>
                </li>
                <li key={(index+10)}>
                    <small style={{ background: backgroundColor[index] == undefined ? backgroundColor[index - 6] : backgroundColor[index], border: `2px solid ${borderColor[index] == undefined ? borderColor[index - 6] : borderColor[index]}` }} />
                    <strong>{`${label}: ${Math.abs(arrayRight[index])} %`}</strong>
                </li>
                </>
            );
        })}
        </ul>
        </>
    );
}

export default HorizontalBar
