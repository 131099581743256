// React
import React, { useEffect, useState } from 'react';

// Style
import { styleTituloCard } from '../../helpers/globals';

// Components
import GraphLine from './GraphLine';

// Grafica
import { Chart as ChartJS, LinearScale, CategoryScale, BarElement, PointElement, LineElement, Legend, Tooltip, LineController, BarController } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import useMediaQuery from '@mui/material/useMediaQuery';

ChartJS.register( LinearScale, CategoryScale, BarElement, PointElement, LineElement, Legend, Tooltip, LineController, BarController, ChartDataLabels );

function CardGraphMultitype({ title , dataMensual, dataSemanal, dataEvent, dataGantt}) {

    const isMobile = useMediaQuery('(max-width:890px)');

    let maxValue = 0;
   
    const dataGraph = dataMensual;
    maxValue = Math.max(...dataGraph);
    
    if (maxValue%20000 != 0) {
        maxValue = maxValue + (20000 - (maxValue%20000))+100000;
    }else{
        maxValue = maxValue + 100000;
    }
    
   
    const data = {
        datasets: [{
            data: dataGraph,
            backgroundColor: 'rgba(204, 204, 204, 0.3)',
            borderColor: 'rgb(204, 204, 204)',
            order: 2,
            borderWidth: 0.1,
            barPercentage: 1.25,
            xAxisID: 'month',
            datalabels: {
                anchor: 'end',
                align: 'top',
                formatter: (value) => {
                    return  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  },
            },
        },
        {
            type: 'bar',
            data: dataSemanal,
            backgroundColor: 'rgb(255, 255, 255)',
            borderColor: 'rgb(153, 153, 153)',
            hoverBackgroundColor: 'rgb(0, 0, 0)',
            borderWidth: 1.5,
            borderRadius: 5,
            barPercentage: 1.3,
            order: 1,
            xAxisID: 'weeks',
            datalabels: {
                display: false
            }
        },
        {
            type: 'line',
            data: dataEvent,
            borderColor: '#cccccc',
            xAxisID: 'weeks',
        }
    ],
    };

    const options = {
        aspectRatio: isMobile ? 1.5 : 3,
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            datalabels: {
                
            },
            title: {
                display: true,
                position: 'bottom',
                align: 'start',
                text: 'SEMANAS',
                color: 'rgb(153, 153, 153)',
                padding: {
                    top: -18, 
                    left: 0, 
                    right: 0, 
                    bottom: 0
                }
            }
        },
        elements: {
            point: {
              radius: 1
            }
          },
        scales: {
            y:{
                suggestedMin: 0,
                max: maxValue,
                min: 0,
                ticks: {
                    color: 'rgb(153, 153, 153)',
                    stepSize: 20000,
                    font: {
                        weight: 'bold',
                        size: 14,
                    }
                },
            },
            month: {
                axis: 'x',
                labels: [  'ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC' ],
                grid: {
                    borderWidth: 0,
                    drawTicks: false,
                },
                ticks: {
                    color: 'black',
                    padding: 10,
                    font: {
                        weight: 'bold',
                        size: 12,
                    }
                },
            },
            weeks: {
                axis: 'x',
                labels: [ '1', '2', '3', '4', '5', '6','7', '8', '9', '10', '11', '12',	'13', '14', '15', '16', '17', '18', '19', '20', '21', '22',	'23', '24', '25', '26', '27', '28', '29', '30', '31', '32',	'33', '34', '35', '36', '37', '38', '39', '40', '41', '42',	'43', '44', '45', '46', '47', '48', '49', '50', '51', '52' ],
                grid: {
                    borderWidth: 0,
                    drawOnChartArea: false,
                    drawTicks: false,
                },
                ticks: {
                    color: 'black',
                    padding: 5,
                },
            },
        },
    };

    return (
        <>
        <div className="card card-overflow">
            <div className="card-content p-3">
                <div className='card-overflow-width'>
                    <p className="has-text-weight-bold" style={styleTituloCard}>{ title.toUpperCase() }</p>

                    <Chart type='bar' options={options} data={data} />
                    <GraphLine dataGantt={dataGantt} />

                </div>
            </div>
        </div>
        </>
    );
}

export default CardGraphMultitype;