// React
import React, { useEffect, useState }  from 'react';
import { Link } from 'react-router-dom';

// Style
import { styleTituloCard } from '../helpers/globals';

// Components
import CardMedium from '../components/Card/CardMedium';
import CardSmall from '../components/DashboardComponents/CardSmall';
import CardGraphPie from '../components/DashboardComponents/CardGraphPie';
import HorizontalBar from '../components/Graph/HorizontalBar';
import CardGraphVerticalBar from '../components/DashboardComponents/CardGraphVerticalBar';
import CardGraphMultitype from '../components/DashboardComponents/CardGraphMultitype';
import { useAuth } from '../contexts/auth';
import { http } from '../helpers/http';
import Spinner from '../components/Spinner/Spinner';

import { PDFViewer, Page, Text, View, Document, StyleSheet,Image,Svg } from '@react-pdf/renderer';
import html2canvas from 'html2canvas';
import Logo from '../icons/HorizontalLogo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faFilePdf } from '@fortawesome/free-solid-svg-icons'
import {  faChevronDown } from '@fortawesome/free-solid-svg-icons';

const styleCard = {
  height: '100%'
};

function Dashboard() {
  const {
    auth,
  } = useAuth();
  

  

  const [lastUpdate, setLastUpdate] = useState('');
  const [ciudad, setCiudad] = useState('');
  const [loadingCampanas, setLoadingCampanas] = useState(true);
  const [loadingAge, setLoadingAge] = useState(true);
  const [loadingImpresiones, setLoadingImpresiones] = useState(true);
  const [loadingSO, setLoadingSO] = useState(true);
  const [loadingCTR, setLoadingCTR] = useState(true);
  const [loadingSemanales, setLoadingSemanales] = useState(true);
  const [loadingGantt, setLoadingGantt] = useState(true);
  const [loadingNavegadores, setLoadingNavegadores] = useState(true);

  const [loadingApps, setLoadingApps] = useState(true);
  const ADMIN = "ADMIN";
  const [cliente, setCliente]= useState([]);
  const [estadoCampanas, setestadoCampanas]= useState([]);

  const [datosMensuales, setdatosMensuales]= useState([]);
  const [datosSemanales, setdatosSemanales]= useState([]);
  const [datosEvent, setDatosEvent]= useState([]);
  const [datosGantt, setdatosGantt]= useState([]);

  const [datosNavegadores, setdatosNavegadores]= useState([]);
  const [datosApps, setdatosApps]= useState([]);
  
  const [marcas, setMarcas]= useState([]);
  const [isLoadMarcas, setIsLoadMarcas]= useState(true);
  


  const [impresiones, setImpresiones]= useState(0);
  const [costoMillar, setCostoMillar]= useState(0);
  const [CTR, setCTR]= useState(0);
  const [conversion, setConversion]= useState(0);
  
  const [dataVerticalBar, setOs]= useState([0,0,0]);


  const [maleData,setmaleData] = useState([0,0,0,0,0,0,0,0]); // data: [2, 18, 15, 10, 3, 0.5, 0], // Hombre
  const [femaleData,setfemaleData] = useState([0,0,0,0,0,0,0,0]); // data: [8, 16, 15, 9, 2, 1.5, 0], // Mujer


  const [secciones, setSecciones] = useState([]);


  

  const styles = StyleSheet.create({
    section: { textAlign: 'center', marginLeft: 40,marginTop: 15,width: 500 },
  });
  const dataClient = [
    {
      name: 'Usuario',
      value: auth.name
    },
    {
      name: 'Empresa',
      value: cliente.nombre_agencia
    },
    {
      name: 'Tipo de Cliente',
      value: cliente.tipo_cliente
    },
  ];

  const plantilla1 = [
    {
      name: 'Video',
      value: '10 / 100%'
    },
    {
      name: 'Video + Mensaje',
      value: '10 / 100%'
    },
    {
      name: 'Video + CTA',
      value: '10 / 100%'
    },
    {
      name: 'Video + Mensaje + CTA',
      value: '10 / 100%'
    },
    {
      name: 'Imagen',
      value: '0 / 0%'
    }
  ]

  const plantilla2 = [
    {
      name: 'Imagen',
      value: '0 / 0%'
    },
    {
      name: 'Imagen + Mensaje',
      value: '0 / 0%'
    },
    {
      name: 'Imagen + CTA',
      value: '0 / 0%'
    },
    {
      name: 'Imagen + Mensaje + CTA',
      value: '0 / 0%'
    }
  ]

  const plantilla3 = [
    {
      name: 'Cupón',
      value: 'X%'
    },
    {
      name: 'Formulario',
      value: 'X%'
    },
    {
      name: 'Encuesta',
      value: 'X%'
    }
  ]

  const dataPie = [estadoCampanas.Activas, estadoCampanas.Pausadas, estadoCampanas.Concluidas];

  const chartDataPie = {
      labels: ['Activas', 'Pausadas', 'Concluidas'],
      datasets: [
        {
          data: dataPie,
          borderColor: ['#ff6686', '#3aa4ec', '#ffce5a'],
          backgroundColor: ['#ffe0e6', '#d7ecfb', '#fff5dd'],
        },
      ],
    };
  
  const chartOptionsPie = {
      responsive: true,
      plugins: {
        legend: {
          display: false,
          position: 'bottom',
        },
      },
  };

  //const dataVerticalBar = [0,0,0];
  const chartDataVerticalBar = {
      labels: ['Android', 'iOS', 'Otros'],
      datasets: [
        {
          data: dataVerticalBar,
          backgroundColor: ['#ffe0e6', '#d7ecfb', '#fff5dd'],
          borderColor: ['#ff6686', '#3aa4ec', '#ffce5a'],
          borderWidth: 1,
          datalabels: {
            color: '#999',
            anchor: 'end',
            align: 'top',
          },
        },
      ],
    };

  const chartOptionsVerticalBar = {
      responsive: true,
      plugins: {
        legend: {
          display: false,
          position: 'bottom',
        },
      },
      scales: {
        y: {
            beginAtZero: true,
            suggestedMin: 0,
            suggestedMax: Math.max(...dataVerticalBar)+(Math.max(...dataVerticalBar)*.1),
        }
    }

  };


  //navegadores data

  const chartDataVerticalBarNavegadores = {
      labels: datosNavegadores.map((item) => item.navegador),
      datasets: [
        {
          data: datosNavegadores.map((item) => item.porcentaje),
          backgroundColor: ['#ffe0e6', 'rgb(215 236 250)', '#fff5de', '#dbf2f2', '#ebe0ff', 'rgba(255, 159, 64, 0.2)', 'rgba(112, 212, 75, 0.2)','rgba(59, 89, 152, 0.2)','#f2f2f2'],
          borderColor: ['#ff6686', '#3aa4ec', '#ffce5a', '#00bfa6', '#7c4dff', 'rgba(255, 159, 64, 1)', 'rgba(112, 212, 75, 1)', 'rgba(59, 89, 152, 1)', '#999'],
          borderWidth: 1,
          datalabels: {
            color: '#999',
            anchor: 'end',
            align: 'top',
          },
        },
      ],
    };

  const chartOptionsVerticalBarNavegadores = {
      responsive: true,
      plugins: {
        legend: {
          display: false,
          position: 'bottom',
        },
      },
      scales: {
        y: {
            beginAtZero: true,
            suggestedMin: 0,
            suggestedMax: Math.max(...datosNavegadores.map((item) => item.porcentaje))+(Math.max(...datosNavegadores.map((item) => item.porcentaje))*.1),
        }
    }

  };



  const chartDataVerticalBarApps = {
    labels: datosApps.map((item) => item.app_user),
    datasets: [
      {
        data: datosApps.map((item) => item.porcentaje),
        backgroundColor: ['#ffe0e6', 'rgb(215 236 250)', '#fff5de', '#dbf2f2', '#ebe0ff', 'rgba(255, 159, 64, 0.2)', 'rgba(112, 212, 75, 0.2)','rgba(59, 89, 152, 0.2)', 'rgba(0, 106, 255, 0.2)', 'rgba(229, 9, 20, 0.2)'],
        borderColor: ['#ff6686', '#3aa4ec', '#ffce5a', '#00bfa6', '#7c4dff', 'rgba(255, 159, 64, 1)', 'rgba(112, 212, 75, 1)', 'rgba(59, 89, 152, 1)', 'rgba(0, 106, 255, 1)', 'rgba(229, 9, 20, 1)'],
        borderWidth: 1,
        datalabels: {
          color: '#999',
          anchor: 'end',
          align: 'top',
        },
      },
    ],
  };

  const chartOptionsVerticalBarApps = {
      responsive: true,
      plugins: {
        legend: {
          display: false,
          position: 'bottom',
        },
      },
      scales: {
        y: {
            beginAtZero: true,
            suggestedMin: 0,
            suggestedMax: Math.max(...datosApps.map((item) => item.porcentaje))+(Math.max(...datosApps.map((item) => item.porcentaje))*.1),
        }
    }

  };

  const dataMarcas = {
    labels: marcas.map(marca => marca.marca),
    datasets: [
      {
        data: marcas.map(marca => marca.porcentaje),
        backgroundColor: ['#ffe0e6', 'rgb(215 236 250)', '#fff5de', '#dbf2f2', '#ebe0ff', 'rgba(255, 159, 64, 0.2)', 'rgba(112, 212, 75, 0.2)','rgba(59, 89, 152, 0.2)', 'rgba(0, 106, 255, 0.2)', 'rgba(229, 9, 20, 0.2)'],
        borderColor: ['#ff6686', '#3aa4ec', '#ffce5a', '#00bfa6', '#7c4dff', 'rgba(255, 159, 64, 1)', 'rgba(112, 212, 75, 1)', 'rgba(59, 89, 152, 1)', 'rgba(0, 106, 255, 1)', 'rgba(229, 9, 20, 1)'],
        borderWidth: 1,
        datalabels: {
          color: '#999',
          anchor: 'end',
          align: 'top',
        },
      }
    ]
  }
  const optionMarcas = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'bottom',
      },
    },
    scales: {
      y: {
          beginAtZero: true,
          suggestedMin: 0,
          suggestedMax: Math.max(...marcas.map(marca => marca.porcentaje)) + (Math.max(...marcas.map(marca => marca.porcentaje))*.1),
      }
    }
  }

  // marcas.map(marca => console.log(marca.marca))


  let labelHorizontalBar = ['-18', '18+', '25+', '35+', '45+', '50+', '55+', '65+'];




  async function fetchCampanas() {
    setLoadingCampanas(true);
    const response2 = await http.get('/dashboard/estado_campanas', {
      params: {
        id_user: auth.id,
        ciudad: ciudad,
      },
    });
    setestadoCampanas(response2.data.data[0]);
    setLoadingCampanas(false);
  }

  async function fetchNavegadores(){
    setLoadingNavegadores(true);
    const response = await http.get('/dashboard_etl/navegadores', {
      params: {
        id_user: auth.id,
        ciudad: ciudad,
      },
    });
    console.log(response.data.data);
    setdatosNavegadores(response.data.data);

    setLoadingNavegadores(false);
  }

  async function fetchApps(){
    setLoadingApps(true);

    const response = await http.get('/dashboard/apps');
    setdatosApps(response.data.data);

    setLoadingApps(false);
  }

  async function obtenerMarcaTelefono() {
    setIsLoadMarcas(true);
    const response = await http.get('/dashboard_etl/marcas', {
      params: {
        id_user: auth.id,
        ciudad: ciudad,
      },
    });
    setMarcas(response.data.data);
    setIsLoadMarcas(false);
  }

  async function fetchImpactosSemanales() {
    setLoadingGantt(true);
    setLoadingSemanales(true);


    const response2 = await http.get('/kpis/graficaSemanal', {
      params: {
        id_user: auth.id,
        anio: 2023,
        ciudad: ciudad,
      }
    });
    const response = await http.get('/kpis/graficaGantCampanas', {
      params: {
        id_user: auth.id,
        anio: 2023,
        ciudad: ciudad,
      },
    });
    
    setdatosGantt(response.data.data);
    setLoadingGantt(false);

   
    setdatosMensuales(response2.data.data.meses);
    setdatosSemanales(response2.data.data.semanas);
    setLoadingSemanales(false);
  }

  async function fetchDataEvent() {
    fetch('https://dev-exporters.wiwi.services/kpis/rawEventos').then(response => response.json()).then(data => setDatosEvent(data.data.semanas));
    // const response2 = await http.get('/kpis/rawEventos');
    // console.log(response2);
  }

  async function fetchCTR() {
    setLoadingCTR(true);
    const response2 = await http.get('/dashboard_etl/familias_dashboard', {
      params: {
        id_user: auth.id,
        ciudad: ciudad,
      },
    });
    //setestadoCampanas(response2.data.data[0]);
    //console.log(response2.data.data);
    setCTR (response2.data.data.ctr);
    setConversion (response2.data.data.conv);
    setLoadingCTR(false);
  }

  async function fetchCliente() {
    const response = await http.get('/records/crea_clientes', {
      params: {
        id_client: auth.id_client,
      },
    });
    const { data } = response;
    setCliente(data.data[0]);
  }

  async function fetchImpresiones() {

    setLoadingImpresiones(true);

    const response3 = await http.get('/dashboard_etl/impresiones_campanas', {
      params: {
        id_user: auth.id,
        ciudad: ciudad,
      },
    });
    setImpresiones(response3.data.data[0].impresionesTotales);
   

    const response4 = await http.get('/dashboard/presupuesto_campanas', {
      params: {
        id_user: auth.id,
        ciudad: ciudad,
      },
    });
    setCostoMillar((response4.data.data[0].presupuestoTotal/(response3.data.data[0].impresionesTotales/1000)).toFixed(2));


    setLoadingImpresiones(false);
  }

  async function fetchSO() {
    setLoadingSO(true);
    const response5 = await http.get('/dashboard_etl/os_data_cliente', {
      params: {
        id_user: auth.id,
        ciudad: ciudad,
      },
    });

    const {
      os
    } = response5.data.data;

    

    const total= 100/(os.Android+os.iOS+os.Otros);
 
   setOs([(os.Android*total).toFixed(1),(os.iOS*total).toFixed(1),(os.Otros*total).toFixed(1)]);


   setLoadingSO(false);
  }

  async function fetchGenero() {
    setLoadingAge(true);
    const response6 = await http.get('/dashboard_etl/kpis_edad', {
      params: {
        id_user: auth.id,
        ciudad: ciudad,
      },
    });
  
  
  
    const {
      hombres,
      mujeres
    
      } = response6.data.data.edad_genero;
    
      const valorPorcentajeHombre= 100/hombres['totales'];
      const valorPorcentajeMujer= 100/mujeres['totales'];
    
  
      setmaleData([
        (hombres['-18']*valorPorcentajeHombre).toFixed(1),
        (hombres['18+']*valorPorcentajeHombre).toFixed(1),
        (hombres['25+']*valorPorcentajeHombre).toFixed(1),
        (hombres['35+']*valorPorcentajeHombre).toFixed(1),
        (hombres['45+']*valorPorcentajeHombre).toFixed(1),
        (hombres['50+']*valorPorcentajeHombre).toFixed(1),
        (hombres['55+']*valorPorcentajeHombre).toFixed(1),
        (hombres['65+']*valorPorcentajeHombre).toFixed(1),
      ]);
      setfemaleData([
        (mujeres['-18']*valorPorcentajeMujer).toFixed(1),
        (mujeres['18+']*valorPorcentajeMujer).toFixed(1),
        (mujeres['25+']*valorPorcentajeMujer).toFixed(1),
        (mujeres['35+']*valorPorcentajeMujer).toFixed(1),
        (mujeres['45+']*valorPorcentajeMujer).toFixed(1),
        (mujeres['50+']*valorPorcentajeMujer).toFixed(1),
        (mujeres['55+']*valorPorcentajeMujer).toFixed(1),
        (mujeres['65+']*valorPorcentajeMujer).toFixed(1),
      ]);
      setLoadingAge(false);
  }

  async function fetchLastUpdate() {


    const response = await http.get('/dashboard_etl/ultima_actualizacion', {
      params: {
        id_user: auth.id,
      },
    });
    const { data } = response;



    let fechas =  new Date(data.data[0].fechaHora);


    let fechaUtc = new Date(Date.UTC(fechas.getFullYear(), fechas.getMonth(), fechas.getDate(), fechas.getHours(), fechas.getMinutes(), fechas.getSeconds()));


    let fecha = fechaUtc.toLocaleString('es-MX', { year : 'numeric', month : 'short', day : '2-digit', hour : '2-digit', minute : '2-digit'});

    
    setLastUpdate(fecha);
  
  }


  const cityOnChange = ({ target }) => {
    setCiudad(target.value);
  };



  useEffect(async () => {
    fetchLastUpdate();

    fetchCliente();
    fetchCTR();
    fetchImpresiones();
    fetchCampanas();
    
    fetchGenero();
    fetchSO();
    {auth.tipo===ADMIN && fetchNavegadores() && obtenerMarcaTelefono()}
    fetchImpactosSemanales();
    fetchDataEvent();
  },[ciudad]);

  useEffect(async () => {
    {auth.tipo===ADMIN && fetchApps()}
  },[]);







  async function captura() {
   
    //comprobar si existe un div con id
    const seccionesExistentes=["info","info2","info3","info4"];

    let s=[];

    for (let i = 0; i < seccionesExistentes.length; i++) {
      
      if(document.getElementById(seccionesExistentes[i])){
    
        const canvas= await html2canvas(document.getElementById(seccionesExistentes[i]));
       
        s.push(canvas.toDataURL());
      }
    }

   
    

    setSecciones(s);

    const modal = document.getElementById('modal');
    modal.classList.add('is-active');
     
  }

  const date = new Date();
  const today = date.toLocaleDateString('es-MX', {
    day: 'numeric', month: 'long', year: 'numeric'
  }).replace(/ /g, ' ');

  const MyDoc = () => (
    <Document >
      <Page size="LETTER" >
        <View style={{marginLeft: 40,marginTop:20,marginBottom:10, flexDirection:'row',alignItems:'center' }} fixed wrap>
          <Image source={{uri: Logo}} style={{width: 100, height: 60}} />
          <Text style={{marginLeft:10, fontSize:10}}>{
          `Dashboard`}</Text>
        </View> 
       
        
        {
          secciones.length > 0 ?
          
            secciones.map((seccion,index)=>{
             
              return(
                <View key={index} id={seccion[index]} style={[styles.section, { color: 'white' }]}>
                  <Image source={{uri: seccion}}  />
                </View>
              )
            }):null
        }
        <View style={{marginLeft: 40,marginTop:20,marginBottom:10, bottom:15,position:'absolute' }} fixed>
          
          <Text style={{marginLeft:10, fontSize:10}}>{
          `Generado por `+auth.name +` el día `+ today }</Text>
        </View>
      </Page>
    </Document>
    );
  
  const closeModal = () => {
    const modal = document.getElementById('modal');
    modal.classList.remove('is-active');
  }






  return (
    <>

      <div className="modal" id='modal' >
        <div className="modal-background" onClick={closeModal} style={{backgroundColor:'transparent'}}></div>
        <div className="modal-card" style={{width:'50%'}}>
          <header className="modal-card-head">
            <p className="modal-card-title">Reporte</p>
            <button style={{backgroundColor:'rgba(112, 212, 75, 1)'}} onClick={closeModal } className="delete" aria-label="close">
              
            </button>
          </header>
          <section className="modal-card-body">
            
            <PDFViewer style={{width:'100%',height:'700px'}}>
              <MyDoc />
            </PDFViewer>
          </section>
         
        </div>
      </div>


    <h4 className="title is-4">Hola {auth.name}!</h4>
    <p className='mb-5' >Listado de todas las campañas</p>

    <div className="tabs">
      <ul className='is-left'>
        <li >
          <Link className='pl-0' to="/" onClick={(e) => e.preventDefault()}>General</Link>
        </li>
      </ul>
      <ul className='is-right'> 
      
        <li>
          <p>Ultima actualización: {lastUpdate}</p>
        </li>
    
        <li >
          <a onClick={captura}> <FontAwesomeIcon className='mr-3' icon={faFilePdf} /> Exportar</a> 
        </li>
        <li >
          <p className="has-text-weight-bold" style={{paddingLeft:'20px',paddingRight:'5px'}}>Ciudad:</p>
        </li>
        <li >
            <div className='field control has-icons-right'>
              <select className='input' onChange={cityOnChange}>
                <option value="">Todas</option>
                <option value="LEON">LEÓN</option>
                <option value="MTY">MTY</option>
                <option value="QRO">QRO</option>
                <option value="SLP">SLP</option>
                <option value="GDL">GDL</option>
                <option value="AGS">AGS</option>
              </select>
              <span className="icon is-small is-right">
                <FontAwesomeIcon icon={faChevronDown} />
              </span>
            </div>
          
        </li>
      </ul>
    </div>

    <div  id='info' >
      <div className='columns'>
        <div className='column is-one-third'>
          <CardMedium title='Info Cliente' data={dataClient} cardHeight={100} isAdjuntar={false} />
        </div>
        <div className='column'>
          <div className='columns is-multiline is-mobile'>
            <div className='column is-half-tablet'>
            <CardSmall title='Total Impresiones' textPrimary={impresiones} textSecundary='Impresiones' isLoading={loadingImpresiones} />
              
            </div>
            <div className='column is-half-tablet'>
              
              <CardSmall title='Click Through Rate' sufijo={"%"} textPrimary={CTR} textSecundary='CTR' isLoading={loadingCTR} />
            </div>
          </div>
          <div className='columns is-multiline is-mobile'>
            <div className='column is-half-tablet'>
            <CardSmall title='Costo Promedio por Millar' prefijo={"$"} textPrimary={costoMillar} isLoading={loadingImpresiones} /> 
            
            </div>
            <div className='column is-half-tablet'>
              <CardSmall title='Tasa de llenado de Forms' sufijo={"%"} textPrimary={conversion} textSecundary='CONV' isLoading={loadingCTR} />
            </div>
          </div>
        </div>
      </div>

      {/* <div className='columns'>
        <div className='column'>
          <CardMedium title='Pantalla 1' data={plantilla1} />
        </div>
        <div className='column'>
          <CardMedium title='Pantalla 2' data={plantilla2} />
        </div>
        <div className='column'>
          <CardMedium title='Pantalla 3' data={plantilla3} />
        </div>
      </div> */}

      <div className='columns is-multiline'>
        <div className='column is-one-third-desktop is-half-tablet'>
          <CardGraphPie title='Campañas por Status' chartData={chartDataPie} chartOptions={chartOptionsPie} isLoading={loadingCampanas} />
        </div>
        <div className='column is-one-third-desktop is-half-tablet'>
          <div className="card" style={ loadingAge ? {} : styleCard}>
            <div className="card-content">
                <p className="has-text-weight-bold" style={styleTituloCard}>Edad y Género</p>
                { loadingAge ? <Spinner /> : <HorizontalBar tableTitle='Mujeres Hombres' leftData={femaleData} rightData={maleData} labels={labelHorizontalBar}/> }
            </div>
          </div>
        </div>
        <div className='column is-one-third-desktop is-half-tablet'>
        <CardGraphVerticalBar title='Sistema Operativo' chartData={chartDataVerticalBar} chartOptions={chartOptionsVerticalBar} isLoading={loadingSO} /> 
        </div>
        {auth.tipo === ADMIN ?
        <>
          <div className='column is-one-third-desktop is-half-tablet'>
            <CardGraphVerticalBar title='Navegadores' chartData={chartDataVerticalBarNavegadores} chartOptions={chartOptionsVerticalBarNavegadores} isLoading={loadingNavegadores} /> 
          </div>
          <div className='column is-one-third-desktop is-half-tablet'>
            <CardGraphVerticalBar title='Apps' chartData={chartDataVerticalBarApps} chartOptions={chartOptionsVerticalBarApps} isLoading={loadingApps} /> 
          </div>
          <div className='column is-one-third-desktop is-half-tablet'>
            <CardGraphVerticalBar title='Marca de teléfonos' chartData={dataMarcas} chartOptions={optionMarcas} isLoading={isLoadMarcas} /> 
          </div>
        </> : null}

       
      </div>

     
    </div>



    
    <div className='columns is-multiline' id='info2'>
      <div className='column is-full'>
        {
          loadingSemanales ? <Spinner /> : 
          <CardGraphMultitype title='Impactos por Semanas / Meses con Línea de Tiempo de Campañas' 
          dataMensual={Object.values(datosMensuales)}
          dataSemanal={Object.values(datosSemanales)}
          dataEvent={Object.values(datosEvent)}
          dataGantt={datosGantt}
          />
        }
       
      </div>
    </div>
    </>
  );
}

export default Dashboard;
