import React, { useEffect, useState } from 'react';
import { Link, useLocation, Outlet,useParams } from 'react-router-dom';
import ReturnImage from '../iconComponents/ReturnImage';
import { http } from '../../helpers/http';
import html2canvas from 'html2canvas';
import { useAuth } from '../../contexts/auth';
import Logo from '../../icons/HorizontalLogo.png';
import { PDFViewer, Page, Text, View, Document, StyleSheet,Image,Svg } from '@react-pdf/renderer';
import ReactDOM from '@react-pdf/renderer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faFilePdf } from '@fortawesome/free-solid-svg-icons'

// Components
import SubMenu from '../NavBar/SubMenu';

function Campaign() {

  const
  auth = useAuth();
  const 
  {pathname} = useLocation();
  const splitLocation = pathname.split("/");
  const seccion = splitLocation[3];
  const [lastUpdate, setLastUpdate] = useState('');
  const dataMenu = [{
      location: 'general',
      path: 'general',
      title: 'General',
      class: 'pl-0',
    },
    {
      location: 'preview',
      path: 'preview',
      title: 'Preview',
      class: '',
    },
    {
      location: 'analytics',
      path: 'analytics',
      title: 'Analítica',
      class: '',
    },
    {
      location: 'log',
      path: 'log',
      title: 'Registros',
      class: '',
  }]
  


  const { id } = useParams();
  const estiloLista = {
    listStyleType: 'none',
    marginTop: '0',
    marginBottom: '0',
    marginLeft: '0',
  };
  const location = useLocation();
  const [campName, setCampName] = useState('');
  const [campaignInfo, setCampaignInfo] = useState({});
  const [secciones, setSecciones] = useState([]);
  const styles = StyleSheet.create({
    section: { textAlign: 'center', marginLeft: 40,marginTop: 15,width: 500 },
  });
  

  useEffect( async () => {
    if (id) {
     
      const response = await http.get('/records/lista_camp', {
        params: {
          id_camp: id,
        },
      });
      let { data } = response;

     
      data.data[0].version= data.data[0].version ? data.data[0].version : '';
      data.data[0].material=data.data[0].material ? data.data[0].material : '';
      setCampaignInfo(data.data[0]);

      fetchLastUpdate();

    }
  }, [id]);






  async function captura() {
   
    //comprobar si existe un div con id
    const seccionesExistentes=["info","info2","info3","info4"];

    let s=[];

    for (let i = 0; i < seccionesExistentes.length; i++) {
      
      if(document.getElementById(seccionesExistentes[i])){
    
        const canvas= await html2canvas(document.getElementById(seccionesExistentes[i]));
       
        s.push(canvas.toDataURL());
      }
    }

   
    

    setSecciones(s);

    const modal = document.getElementById('modal');
    modal.classList.add('is-active');
     
  }


  async function fetchLastUpdate() {
    const response = await http.get('/dashboard_etl/ultima_actualizacion', {
      params: {
        id_user: auth.id,
      },
    });
    const { data } = response;



    let fechas =  new Date(data.data[0].fechaHora);


    let fechaUtc = new Date(Date.UTC(fechas.getFullYear(), fechas.getMonth(), fechas.getDate(), fechas.getHours(), fechas.getMinutes(), fechas.getSeconds()));


    let fecha = fechaUtc.toLocaleString('es-MX', { year : 'numeric', month : 'short', day : '2-digit', hour : '2-digit', minute : '2-digit'});

    
    setLastUpdate(fecha);
  
  }


  const date = new Date();
  const today = date.toLocaleDateString('es-MX', {
    day: 'numeric', month: 'long', year: 'numeric'
  }).replace(/ /g, ' ');

  const MyDoc = () => (
    <Document >
      <Page size="LETTER" >
        <View style={{marginLeft: 40,marginTop:20,marginBottom:10, flexDirection:'row',alignItems:'center' }} fixed wrap>
          <Image source={{uri: Logo}} style={{width: 100, height: 60}} />
          <Text style={{marginLeft:10, fontSize:10}}>{
          `Campaña: `+campaignInfo.nombre+ ` / ` 
          +campaignInfo.version+ ` / `+campaignInfo.material +
          `\n`+campaignInfo.cliente}</Text>
        </View> 
       
        
        {
          secciones.length > 0 ?
          
            secciones.map((seccion,index)=>{
             
              return(
                <View key={index} id={seccion[index]} style={[styles.section, { color: 'white' }]}>
                  <Image source={{uri: seccion}}  />
                </View>
              )
            }):null
        }
        <View style={{marginLeft: 40,marginTop:20,marginBottom:10, bottom:15,position:'absolute' }} fixed>
          
          <Text style={{marginLeft:10, fontSize:10}}>{
          `Generado por `+auth.auth.name +` el día `+ today }</Text>
        </View>
      </Page>
    </Document>
    );
  
  const closeModal = () => {
    const modal = document.getElementById('modal');
    modal.classList.remove('is-active');
  }




  return (
    <>
     <div className="modal" id='modal' >
        <div className="modal-background" onClick={closeModal} style={{backgroundColor:'transparent'}}></div>
        <div className="modal-card" style={{width:'50%'}}>
          <header className="modal-card-head">
            <p className="modal-card-title">Reporte</p>
            <button style={{backgroundColor:'rgba(112, 212, 75, 1)'}} onClick={closeModal } className="delete" aria-label="close">
              
            </button>
          </header>
          <section className="modal-card-body">
            
            <PDFViewer style={{width:'100%',height:'700px'}}>
              <MyDoc />
            </PDFViewer>
          </section>
         
        </div>
      </div>
      <section className="content-header mb-5">
        <section className="section-title mb-5">
          <h4 className="title is-4">Campaña</h4>
         {
          campaignInfo.id_camp && (
            <p>{`Vista General de ${campaignInfo.nombre} / ${campaignInfo.version} / ${campaignInfo.material} - ${campaignInfo.ciudad}`}</p>
          )
         }
        </section>
        <div className="tabs is-hidden-mobile">
          <ul >
            <SubMenu data={dataMenu} />
          </ul>

          <ul className='is-right'> 
            
            { seccion=== 'analytics' ? (
              <li>
                <p>Ultima actualización: {lastUpdate}</p>
              </li>
            ) : null}	
            
            {seccion=== 'general' || seccion=== 'analytics' ? (
               <li >
               <a onClick={captura}> <FontAwesomeIcon className='mr-3' icon={faFilePdf} /> Exportar</a>
              </li>
            ) : null}	
            <li >
              <Link className='pr-0' to="/campaigns"> <FontAwesomeIcon className='mr-3' icon={faList} /> Listado</Link>
            </li>
          </ul>
          {/* <ul className="nav-actions">
            <li className="icon-left">
              <Link to="/campaigns">
                <ReturnImage />
                Regresar al Listado
              </Link>
            </li>
          </ul> */}
        </div>

        {/* TABS IN MOBILE */}
        <div className='tabs is-fullwidth is-hidden-tablet mb-0'>
          <ul className='style-tabs-mobile'>
            <SubMenu data={dataMenu.slice(0, 2)} />
          </ul>
        </div>
        <div className='tabs is-fullwidth is-hidden-tablet mb-0'>
          <ul className='style-tabs-mobile'>
            <SubMenu data={dataMenu.slice(2, 4)} />
          </ul>
        </div>
        <div className='tabs is-fullwidth is-hidden-tablet'>
          <ul className='style-tabs-mobile'>
            { seccion === 'general' || seccion === 'analytics' ?
              <li style={{ borderRight: "1px solid rgb(219, 219, 219)" }}>
                <a className='px-0' onClick={captura}> <FontAwesomeIcon className='mr-3' icon={faFilePdf} /> Exportar</a>
              </li>
              : 
              null
            }
            <li>
              <Link className='px-0' to="/campaigns"> <FontAwesomeIcon className='mr-3' icon={faList} /> Listado</Link>
            </li>
          </ul>
        </div>
        {/* TABS IN MOBILE */}

      </section>
      
      <Outlet />

     
    </>
  );
}

export default Campaign;
