// React
import React from 'react';

// Style
import { styleTituloCard } from '../../helpers/globals';

// Graficas
import { Chart as CharJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import Spinner from '../Spinner/Spinner';

CharJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function CardGraphVerticalBar({ title, chartData, chartOptions, isLoading }) {

    const styleCard = {
        height: '100%'
    };

    const { borderColor, backgroundColor, data } = chartData.datasets[0]

    return (
        <>
        <div className="card" style={styleCard}>
            <div className="card-content">
                <p className="has-text-weight-bold" style={styleTituloCard}>{ title.toUpperCase() }</p>
                { isLoading ? <Spinner /> :<> 
                <Bar options={chartOptions} data={chartData} width={100} height={100} />

                <ul className="legends ml-0">

                    {chartData.labels.map((label, index) => {
                        return(
                            <li key={index}>
                                <small style={{ background: backgroundColor[index], border: `2px solid ${borderColor[index]}` }} />
                                <strong>{`${label}: ${data[index]}%`}</strong>
                            </li>
                        );
                    })}

                </ul>
                </>}
            </div>
        </div>
        </>
    );
}

export default CardGraphVerticalBar;