import React from 'react';

function AccountIcon(props) {
  return (
    <svg viewBox="0 0 50 50" width={20} height={20} fill="currentColor" {...props}>
      <path
        d="M44.326,43.5H7.014C4.29,43.5,0.5,40.157,0.5,36.867V12.52c0-3.435,3.952-6.02,6.514-6.02h37.312
            c1.255,0,4.174,0.586,4.174,6.02v24.347C48.5,43.021,45.306,43.5,44.326,43.5z M7.014,10.5c-0.769,0-2.514,1.174-2.514,2.02v24.347
            c0,0.954,1.796,2.633,2.529,2.633h37.046c0.177,0,0.425-1.211,0.425-2.633V12.52c0-1.172-0.179-1.785-0.295-2.02H7.014z"
        stroke="currentColor"
      />
      <path
        d="M25.164,31.608c-0.22-2.377-2.386-5.087-5.262-6.4c1.555-1.052,2.579-2.831,2.579-4.845c0-3.222-2.62-5.842-5.843-5.842
            c-3.221,0-5.842,2.621-5.842,5.842c0,1.934,0.948,3.646,2.4,4.71c-3.188,1.323-5.58,4.632-5.58,7.177c0,1.104,0.896,2.25,2,2.25
            h13.565c0.007,0,0.014,0,0.02,0c1.105,0,2-1.146,2-2.25C25.201,32.116,25.188,31.735,25.164,31.608z"
        stroke="currentColor"
      />
      <path d="M40.5,21.5h-13c-1.104,0-2-0.896-2-2s0.896-2,2-2h13c1.104,0,2,0.896,2,2S41.604,21.5,40.5,21.5z" stroke="currentColor" />
      <path d="M37.5,29.5h-10c-1.104,0-2-0.896-2-2s0.896-2,2-2h10c1.104,0,2,0.896,2,2S38.604,29.5,37.5,29.5z" stroke="currentColor" />
    </svg>
  );
}

export default AccountIcon;
