import React, { useState, useEffect } from 'react';
import {
  Chart as CharJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

CharJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function AgeAndGenre({
  femaleInfo, maleInfo, femaleTotal, maleTotal,
}) {
  const male = maleInfo;
  const total = femaleTotal + maleTotal;
  const femaleP = ((femaleTotal * 100) / total).toFixed(1);
  const maleP = ((maleTotal * 100) / total).toFixed(1);
  const [chartData, setChartData] = useState({
    datasets: [],
  });

  const [chartOptions, setChartOptions] = useState({});

  // Este bloque convierte los valores a negativos para que la gráfica vaya hacia la izquierda
  const female = femaleInfo;
  const femaleData = [];
  female.forEach((el) => femaleData.push(el * -1));

  useEffect(() => {
    setChartData({
      labels: ['-18', '18+', '25+', '35+', '45+', '50+', '55+', '65+'],
      datasets: [
        {
          label: 'Mujeres',
          data: femaleData,
          backgroundColor: ['rgba(112,212,75,1)', 'rgba(112,212,75,0.875)', 'rgba(112,212,75,0.75)', 'rgba(112,212,75,0.625)', 'rgba(112,212,75,0.5)', 'rgba(112,212,75,0.375)', 'rgba(112,212,75,0.25)', 'rgba(112,212,75,0.25)'],
          borderColor: ['rgba(112,212,75,1)'],
          borderWidth: 2,
          borderRadius: 0,
          datalabels: {
            display: false,
          },
        },
        {
          label: 'Hombres',
          data: male,
          borderWidth: 2,
          borderRadius: 0,
          backgroundColor: ['rgba(112,212,75,1)', 'rgba(112,212,75,0.875)', 'rgba(112,212,75,0.75)', 'rgba(112,212,75,0.625)', 'rgba(112,212,75,0.5)', 'rgba(112,212,75,0.375)', 'rgba(112,212,75,0.25)', 'rgba(112,212,75,0.25)'],
          borderColor: ['rgba(112,212,75,1)'],
          datalabels: {
            display: false,
          },
        },
      ],
    });
    setChartOptions({
      responsive: true,
      indexAxis: 'y',
      scales: {
        xAxes: {
          stacked: true,
          ticks: {
            callback(label) {
              return Math.abs(label);
            },
          },
        },
        yAxes: {
          beginAtZero: true,
          stacked: true,
        },
      },
      plugins: {
        tooltip: {
          yAlign: 'bottom',
          titleAlign: 'center',
          callbacks: {
            label(context) {
              return `${context.dataset.label} ${Math.abs(context.raw)}`;
            },
          },
        },
        legend: {
          display: false,
          position: 'bottom',
        },
        title: {
          display: true,
          text: `${femaleP}%  Mujeres - Hombres  ${maleP}%`,
        },
      },
    });
  }, [male[0], female[0]]);

  if (!male[0] || !female[0]) {
    return <>Esta gráfica no está disponible por el momento ❌</>;
  }
  // se realizo una correcion de un typo --- karlo 30jul22
  return (
    <>
      <Bar options={chartOptions} data={chartData} width={100} height={100} />
      <ul className="legends col2">
        <li>
          <small style={{ background: 'rgba(112,212,75,1)', border: '2px solid rgba(112,212,75,1)' }} />
          <strong>{`-18 : ${femaleInfo[0]}%`}</strong>
        </li>
        <li>
          <small style={{ background: 'rgba(112,212,75,1)', border: '2px solid rgba(112,212,75,1)' }} />
          <strong>{`-18 : ${maleInfo[0]}%`}</strong>
        </li>
        <li>
          <small style={{ background: 'rgba(112,212,75,0.875)', border: '2px solid rgba(112,212,75,1)' }} />
          <strong>{`18+ : ${femaleInfo[1]}%`}</strong>
        </li>
        <li>
          <small style={{ background: 'rgba(112,212,75,0.875)', border: '2px solid rgba(112,212,75,1)' }} />
          <strong>{`18+ : ${maleInfo[1]}%`}</strong>
        </li>
        <li>
          <small style={{ background: 'rgba(112,212,75,0.75)', border: '2px solid rgba(112,212,75,1)' }} />
          <strong>{`25+ : ${femaleInfo[2]}%`}</strong>
        </li>
        <li>
          <small style={{ background: 'rgba(112,212,75,0.75)', border: '2px solid rgba(112,212,75,1)' }} />
          <strong>{`25+ : ${maleInfo[2]}%`}</strong>
        </li>
        <li>
          <small style={{ background: 'rgba(112,212,75,0.625)', border: '2px solid rgba(112,212,75,1)' }} />
          <strong>{`35+ : ${femaleInfo[3]}%`}</strong>
        </li>
        <li>
          <small style={{ background: 'rgba(112,212,75,0.625)', border: '2px solid rgba(112,212,75,1)' }} />
          <strong>{`35+ : ${maleInfo[3]}%`}</strong>
        </li>
        <li>
          <small style={{ background: 'rgba(112,212,75,0.5)', border: '2px solid rgba(112,212,75,1)' }} />
          <strong>{`45+ : ${femaleInfo[4]}%`}</strong>
        </li>
        <li>
          <small style={{ background: 'rgba(112,212,75,0.5)', border: '2px solid rgba(112,212,75,1)' }} />
          <strong>{`45+ : ${maleInfo[4]}%`}</strong>
        </li>
        <li>
          <small style={{ background: 'rgba(112,212,75,0.375)', border: '2px solid rgba(112,212,75,1)' }} />
          <strong>{`50+ : ${femaleInfo[5]}%`}</strong>
        </li>
        <li>
          <small style={{ background: 'rgba(112,212,75,0.375)', border: '2px solid rgba(112,212,75,1)' }} />
          <strong>{`50+ : ${maleInfo[5]}%`}</strong>
        </li>
        <li>
          <small style={{ background: 'rgba(112,212,75,0.25)', border: '2px solid rgba(112,212,75,1)' }} />
          <strong>{`55+ : ${femaleInfo[6]}%`}</strong>
        </li>
        <li>
          <small style={{ background: 'rgba(112,212,75,0.25)', border: '2px solid rgba(112,212,75,1)' }} />
          <strong>{`55+ : ${maleInfo[6]}%`}</strong>
        </li>
        <li>
          <small style={{ background: 'rgba(112,212,75,0.25)', border: '2px solid rgba(112,212,75,1)' }} />
          <strong>{`65+ : ${femaleInfo[7]}%`}</strong>
        </li>
        <li>
          <small style={{ background: 'rgba(112,212,75,0.25)', border: '2px solid rgba(112,212,75,1)' }} />
          <strong>{`65+ : ${maleInfo[7]}%`}</strong>
        </li>
      </ul>
    </>
  );
}

export default AgeAndGenre;
