import React, {
  useCallback, useEffect, useState,
} from 'react';
import { useParams,useLocation } from 'react-router-dom';
import ScreenPreview from './PreviewComponents/ScreenPreview';
import { http } from '../../helpers/http';
import Spinner from '../Spinner/Spinner';


function Preview() {
  const [loading, setLoading] = useState(true);
  // const screens = [
  //   {
  //     titulo: 'Pantalla 1',
  //     url: 'https://wiwi.cloudmarketingmx.com/wifi/educem/educem_video.php',
  //     obligatorio: true
  //   },
  //   {
  //     titulo: 'Pantalla 2',
  //     url: 'https://wiwi.cloudmarketingmx.com/wifi/educem/educem_imagen.php',
  //     obligatorio: false
  //   },
  //   {
  //     titulo: 'Pantalla 3',
  //     url: 'https://wiwi.cloudmarketingmx.com/wifi/educem/educem_landing.php',
  //     obligatorio: false
  //   }
  // ];

  const [screens, setScreens] = useState([]);

 
  const { id: campaignId } = useParams();

  useEffect(async () =>{
    try {
      const response = await http.get('records/lista_camp', {
        params: {
          id_camp: campaignId,
        },
      });
      
      const { data } = response;

    

      setScreens([]);
      

      const rutaPrincipal= data.data[0].url;

      //console.log(rutaPrincipal.substring(0,rutaPrincipal.length-5));

      for (let i = 0; i <data.data[0].pantallas; i++) {
        const element = {
          titulo: `Pantalla ${i + 1}`.toUpperCase(),
          url: `${rutaPrincipal.substring(0,rutaPrincipal.length-5)}${i+1}.php`,
          obligatorio: i==0 ? true : false
        }



        setScreens(screens => [...screens, element]);
       }
      setLoading(false);
    } catch (err) {
      // console.error(err);
    } finally {
      
    }
  }, [campaignId]);


  return (
  
      <div className="card" id='info'>
        <div className="container has-padding">
          <div className="columns" >
            {loading ? (
              <div className="column has-text-centered">
                <Spinner/>
              </div>
            ) : (
            screens.map((screen, index) => (
              <ScreenPreview key={index} {...screen} />
            )))}
          </div>
        </div>
      </div>
  );
}

export default Preview;
