export const optionsDate = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
};

export const optionsDateTime = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  
};


export const optionsCurrency = {
  style: 'currency',
  currency: 'MXN',
  minimumFractionDigits: 0,
};
