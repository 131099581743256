import React, { useMemo } from 'react';
import { Routes, Route } from 'react-router-dom';
import './sass/base.sass';
import Protected from './pages/Protected';
import Login from './pages/Login';
import Missing from './pages/Missing';

import { useAuth } from './contexts/auth';

function App() {
  const {
    auth,
  } = useAuth();

  const routes = useMemo(() => {
    if (auth.logged) {
      return (
        <Route path="*" element={<Protected />} />
      );
    }

    return (
      <>
        <Route index element={<Login />} />
        <Route path="/" element={<Login />} />
        <Route path="*" element={<Missing />} />
      </>
    );
  }, [auth.logged]);

  return (
    <Routes>
      {routes}
    </Routes>
  );
}

export default App;