import React from 'react';

function CampaignsIcon(props) {
  return (
    <svg viewBox="0 0 50 50" height={20} width={20} fill="currentColor" {...props}>
      <path
        d="M29.825,10.5h-9.194c-0.889,0-1.609-0.545-1.609-1.5s0.72-1.5,1.609-1.5h9.194c0.889,0,1.609,0.545,1.609,1.5
        S30.714,10.5,29.825,10.5z"
        stroke="currentColor"
      />
      <path
        d="M38.039,12.5H36.5V6.062c0-0.568-0.833-0.562-1.679-0.562h-0.153c-0.846,0-1.168-0.006-1.168,0.562V12.5h-2.165
        c-0.529,0-0.835,0.184-0.835,1.093v0.164c0,0.909,0.306,1.743,0.835,1.743h6.704c0.528,0,1.461-0.834,1.461-1.743v-0.164
        C39.5,12.684,38.567,12.5,38.039,12.5z"
        stroke="currentColor"
      />
      <path
        d="M19.039,12.5H17.5V6.062c0-0.568-0.833-0.562-1.679-0.562h-0.153c-0.846,0-1.168-0.006-1.168,0.562V12.5h-2.165
        c-0.529,0-0.835,0.184-0.835,1.093v0.164c0,0.909,0.306,1.743,0.835,1.743h6.704c0.528,0,1.461-0.834,1.461-1.743v-0.164
        C20.5,12.684,19.567,12.5,19.039,12.5z"
        stroke="currentColor"
      />
      <path
        d="M44.998,33.97l-0.002-23.191l0.002-0.341c0.008-0.859,0.015-1.749-0.651-2.471c-0.657-0.712-1.556-0.712-1.851-0.712
        h-3.017c-0.889,0-1.608,0.773-1.608,1.729c0,0.955,0.72,1.728,1.608,1.728h2.299v4.612c-0.764,0.148-1.783,0.425-2.806,0.939
        c-2.826,1.419-4.32,3.995-4.32,7.371c0,1.363-0.134,2.005-0.224,2.282c-0.711-0.268-2.254-1.598-3.41-2.594l-0.408-0.352
        c-2.35-2.019-5.739-2.675-8.842-1.715c-2.404,0.745-4.16,2.334-4.815,4.36c-0.443,1.367-2.358,2.856-4.504,2.894
        c-1.237,0.021-2.902-0.471-3.786-2.939c-0.094-0.261-0.252-0.474-0.444-0.634V10.85c0-0.062,0.354-0.138,0.662-0.138h2.097
        c0.889,0,1.609-0.773,1.609-1.728c0-0.955-0.72-1.729-1.609-1.729H8.88C6.595,7.255,5,8.704,5,10.779v22.845
        c0,2.091,1.827,4.742,3.88,4.742h33.616c0.63,0,1.214-0.257,1.645-0.723C45.017,36.695,45.007,35.247,44.998,33.97z
        M41.771,34.909H8.982c-0.303-0.175-0.764-0.889-0.764-1.285v-3.698c1.132,1.006,2.547,1.548,4.15,1.548
        c0.042,0,0.083-0.001,0.125-0.002c3.092-0.054,6.196-2.198,7.064-4.882c0.353-1.09,1.465-2.02,2.975-2.487
        c2.224-0.69,4.718-0.225,6.355,1.181l0.405,0.347c2.669,2.3,4.598,3.957,6.371,3.043c1.539-0.793,1.747-3,1.747-5.05
        c0-3.687,2.665-4.877,4.367-5.261v15.262l0.002,0.372C41.782,34.253,41.784,34.608,41.771,34.909z"
        stroke="currentColor"
      />
      <path
        d="M39.02,39.848c-0.762,0-1.379,0.663-1.379,1.481v0.494h-2.758v-0.494c0-0.818-0.618-1.481-1.38-1.481
        s-1.379,0.663-1.379,1.481v0.494h-2.299v-0.494c0-0.818-0.617-1.481-1.379-1.481s-1.379,0.663-1.379,1.481v0.494h-3.218v-0.494
        c0-0.818-0.618-1.481-1.379-1.481s-1.379,0.663-1.379,1.481v0.494h-2.758v-0.494c0-0.818-0.617-1.481-1.379-1.481
        s-1.379,0.663-1.379,1.481v0.494h-2.758v-0.494c0-0.818-0.618-1.481-1.379-1.481c-0.761,0-1.379,0.663-1.379,1.481v1.976
        c0,0.818,0.618,1.481,1.379,1.481H39.02c0.763,0,1.38-0.663,1.38-1.481v-1.976C40.399,40.511,39.782,39.848,39.02,39.848z"
        stroke="currentColor"
      />
    </svg>
  );
}

export default CampaignsIcon;
