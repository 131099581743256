import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/auth';
import { http } from '../helpers/http';
import { optionsDate, optionsCurrency } from '../helpers/formatter';
import Spinner from '../components/Spinner/Spinner';

// DatePicker
import DatePicker from 'react-datepicker';

// Icons
import {FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faCalendarDays, faCircleChevronDown, faCheck, faEllipsis, faCirclePause, faChevronDown } from '@fortawesome/free-solid-svg-icons';



import Pages from './../components/CampaignComponents/LogComponents/Pages';
import NumberFormat from 'react-number-format';
function Campaigns() {
  const [loading, setLoading] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [rawCampaigns, setRawCampaigns] = useState([]);
  const [searchByName, setSearchByName] = useState('');
  const [searchByStatus, setsearchByStatus] = useState('');
  const [searchByDate, setsearchByDate] = useState('');
  const [searchByCity, setsearchByCity] = useState('');
  const [datePickerInfo, setDatePickerInfo] = useState(null);
  const [isCollapse, setIsCollapse] = useState(false);


  const [totalRegistros, setTotalRegistros] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const logsPerPage = 30;
  
  const pages = (pageNumber) => {
    setCurrentPage(pageNumber);
  };






  async function fetchCampanas() {
    const response2 = await http.get('/dashboard/estado_campanas', {
      params: {
        id_user: auth.id,
      },
    });
    const suma = response2.data.data[0].Activas+response2.data.data[0].Pausadas+response2.data.data[0].Concluidas;
    //console.log(suma);
    setTotalRegistros(suma);
  }

  const estiloSinSalto = {
    whiteSpace: 'nowrap',	
  };
  const nameOnChange = ({ target }) => {
    setSearchByName(target.value);
  };

  const statusOnChange = ({ target }) => {
    setsearchByStatus(target.value);
  }; 
  
  const cityOnChange = ({ target }) => {
    setsearchByCity(target.value);
  };

  const dateOnChange = date => {
    let  customDate = `${date.getFullYear()}-${ date.getMonth() < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}-${date.getDate()}`;
  
    setsearchByDate(customDate);
    setDatePickerInfo(date);
  };

  const estiloLista = {
    listStyleType: 'none',
    marginTop: '0',
    marginBottom: '0',
    marginLeft: '0',
  };

  const styleCardTable = {
    whiteSpace: 'break-spaces',
  };

  const estiloBoton = {
    width: '100%',
    color: '#fff',
  };
  

  const styleTituloCard = {
    color: '#999',
    fontSize: '12px',
    marginBottom: '5px',
  };

  const {
    auth,
  } = useAuth();



  const fetchCampaigns = useCallback(async () => {
    setLoading(true);
    try {
      const response = await http.get('/records/lista_camp', {
        params: {
          id_client: auth.id_client,
          id_user: auth.id,
          pagina: currentPage,
        },
      });
      const { data } = response;
      setRawCampaigns(data.data);
    } finally {
      setLoading(false);
    }
  }, [setCampaigns,currentPage]);

  const toggleCollapseTable = (evt, id) => {

    document.querySelectorAll(`#data-${id}`).forEach(el => {
      el.classList.toggle('is-hidden-mobile');
    });

    document.querySelector(`.data-${id}`).classList.toggle('is-hidden-mobile');

    setIsCollapse(isCollapse => !isCollapse);

  }

  // <FontAwesomeIcon icon={faCalendarDays} />
  // #408c25

  const renderCampaign = useCallback((campaign) => (
    
    <>
    <tr key={campaign.id_camp}>
      <td className='is-hidden-tablet custom-chevron-icon fas fa-lg py-2' onClick={ evt => toggleCollapseTable(evt, campaign.id_camp)}>
        <FontAwesomeIcon icon={ faCircleChevronDown } />
      </td>
      <td className='is-hidden-mobile'>
        <div style={{ display: 'flex', alignItems: 'center', height: '28px' }}>
          {campaign.id_camp}
        </div>
      </td>
      {/* <td className='is-hidden-desktop-only is-hidden-touch'>{ campaign.cliente }</td> */}
      <td className='is-hidden-desktop-only is-hidden-touch'>
        <div style={{ display: 'flex', alignItems: 'center', height: '28px' }}>
          {campaign.cliente.length > 20 ? campaign.cliente.slice(0, 20) : campaign.cliente }
        </div>
      </td>
      {/* <td>{ campaign.nombre }</td> */}
      <td>
        <div style={{ display: 'flex', alignItems: 'center', height: '28px' }}>
          {campaign.nombre.length > 20 ? campaign.nombre.slice(0, 20) : campaign.nombre }
        </div>
      </td>
      <td>
        {campaign.ciudad != null ?
          <div style={{ display: 'flex', alignItems: 'center', height: '28px' }}>
            {campaign.ciudad.length > 20 ? campaign.ciudad.slice(0, 20) : campaign.ciudad }
          </div> :
          <div style={{ display: 'flex', alignItems: 'center', height: '28px' }}>
            Sin ciudad
          </div>
        }
      </td>
      {/* Función Intl para "formatear" la fecha recibida desde el Backend. */}
      <td className='is-hidden-tablet-only is-hidden-touch'>
        <div style={{ display: 'flex', alignItems: 'center', height: '28px' }}>
          {new Intl.DateTimeFormat('es-MX', optionsDate).format(new Date(campaign.inicio))}
        </div>
      </td>
      <td className='is-hidden-tablet-only is-hidden-touch'>
      <div style={{ display: 'flex', alignItems: 'center', height: '28px' }}>
        {new Intl.DateTimeFormat('es-MX', optionsDate).format(new Date(campaign.fin))}
      </div>
      </td>
      <td className='is-hidden-mobile'>
      <div style={{ display: 'flex', alignItems: 'center', height: '28px' }}>
        {campaign.dias}
      </div>
      </td>
      <td className='is-hidden-desktop-only is-hidden-touch'>
      <div style={{ display: 'flex', alignItems: 'center', height: '28px' }}>
        { <NumberFormat value={campaign.total_impresiones} displayType='text' thousandSeparator={true} />}
      </div>
      </td>
      <td className='is-hidden-mobile'>
      <div style={{ display: 'flex', alignItems: 'center', height: '28px' }}>
      {new Intl.NumberFormat('es-MX', optionsCurrency).format(campaign.presupuesto)}
      </div>
      </td>
      {/* <td className='is-hidden-desktop-only is-hidden-touch' >
      <div style={{ display: 'flex', alignItems: 'center', height: '28px' }}>
      { campaign.material != null ? campaign.material.length > 8 ? campaign.material.slice(0, 8) : campaign.material : '' }
      </div>
      </td>
      <td className='is-hidden-tablet-only is-hidden-touch'>
        <div style={{ display: 'flex', alignItems: 'center', height: '28px' }}>
          { campaign.version != null ? campaign.version.length > 8 ? campaign.version.slice(0, 8) : campaign.version : '' }
        </div>
      </td> */}
      <td>
        <div style={{ display: 'flex', alignItems: 'center', height: '28px' }}>
          { campaign.estado === 'ACTIVA' ? <FontAwesomeIcon style={{ color: '#0074c7', width: '1.25rem', height: '1.25rem' }} icon={faEllipsis} /> : ( campaign.estado === 'TERMINADA' ? <FontAwesomeIcon style={{ color: '#4c9a30', width: '1.25rem', height: '1.25rem' }} icon={faCheck} /> : ( campaign.estado === 'INACTIVA' ? <FontAwesomeIcon style={{ color: '#fbcf23', width: '1.25rem', height: '1.25rem' }} icon={faCirclePause} /> : campaign.estado ) ) }
        </div>
      </td>
      {/* Se le pasa como props el nombre de la campaña a cada botón,
      para que pueda mostrar el nombre de la campaña en el Campaign.jsx */}
        {/* {  { width: '100%', color: '#fff', color:  }} */}
      {/* <td className='is-hidden-mobile'><Link style={{ width: '100%', color: '#fff', background: '#4c9a30' }} className='button is-small' to={`/campaign/${campaign.id_camp}`} state={{ campName: campaign.nombre }}>VER</Link></td> */}
      <td className='is-hidden-mobile'><Link style={ campaign.estado === 'ACTIVA' ? { width: '100%', color: '#fff', background: '#0074c7' } : ( campaign.estado === 'TERMINADA' ? { width: '100%', color: '#fff', background: '#4c9a30' } : { width: '100%', color: '#fff', background: '#fbcf23' } ) } className='button is-small' to={`/campaign/${campaign.id_camp}`} state={{ campName: campaign.nombre }}>VER</Link></td>
    </tr>
    <tr className={`is-hidden-mobile is-hidden-tablet data-${campaign.id_camp}`}>
      <td colSpan={3}>
        <div className='is-flex is-justify-content-space-between'>
          <div className='column p-0 has-text-weight-bold'>
            ID
          </div>
          <div className='column p-0'>
            <span>{campaign.id_camp}</span>
          </div>
        </div>
        <div className='is-flex is-justify-content-space-between'>
          <div className='column p-0 has-text-weight-bold'>
            Cliente
          </div>
          <div className='column p-0'>
            <span style={styleCardTable}>{campaign.cliente}</span>
          </div>
        </div>
        <div className='is-flex is-justify-content-space-between'>
          <div className='column p-0 has-text-weight-bold'>
            Campaña
          </div>
          <div className='column p-0'>
            <span style={styleCardTable}>{campaign.nombre}</span>
          </div>
        </div>
        <div className='is-flex is-justify-content-space-between'>
          <div className='column p-0 has-text-weight-bold'>
            Fecha Inicio
          </div>
          <div className='column p-0'>
            <span>{new Intl.DateTimeFormat('es-MX', optionsDate).format(new Date(campaign.inicio))}</span>
          </div>
        </div>
        <div className='is-flex is-justify-content-space-between'>
          <div className='column p-0 has-text-weight-bold'>
            Fecha Fin
          </div>
          <div className='column p-0'>
            <span>{new Intl.DateTimeFormat('es-MX', optionsDate).format(new Date(campaign.fin))}</span>
          </div>
        </div>
        <div className='is-flex is-justify-content-space-between'>
          <div className='column p-0 has-text-weight-bold'>
            Días
          </div>
          <div className='column p-0'>
            <span>{campaign.dias}</span>
          </div>
        </div>
        <div className='is-flex is-justify-content-space-between'>
          <div className='column p-0 has-text-weight-bold'>
            Imp. Prog.
          </div>
          <div className='column p-0'>
            <span>{campaign.total_impresiones}</span>
          </div>
        </div>
        <div className='is-flex is-justify-content-space-between'>
          <div className='column p-0 has-text-weight-bold'>
            Presupuesto
          </div>
          <div className='column p-0'>
            <span>{new Intl.NumberFormat('es-MX', optionsCurrency).format(campaign.presupuesto)}</span>
          </div>
        </div>
        <div className='is-flex is-justify-content-space-between'>
          <div className='column p-0 has-text-weight-bold'>
            Material
          </div>
          <div className='column p-0'>
            <span>{campaign.material}</span>
          </div>
        </div>
        <div className='is-flex is-justify-content-space-between'>
          <div className='column p-0 has-text-weight-bold'>
            Versión
          </div>
          <div className='column p-0'>
            <span>{campaign.version}</span>
          </div>
        </div>
        <div className='is-flex is-justify-content-space-between'>
          <div className='column p-0 has-text-weight-bold'>
            Estado
          </div>
          <div className='column p-0'>
            <span>{campaign.estado}</span>
          </div>
        </div>
        <div className='mt-5'>
          <Link style={estiloBoton} className='button is-primary is-small' to={`/campaign/${campaign.id_camp}`} state={{ campName: campaign.nombre }}>VER</Link>
        </div>
      </td>
    </tr>
    </>
  ), [isCollapse]);

  const campaignsList = useMemo(() => campaigns.map(renderCampaign), [campaigns, isCollapse]);

  const hasMatch = (_value, _testValue) => {
    const value = _value.toLocaleLowerCase().trim();
    const testValue = _testValue.toLocaleLowerCase().trim();
    return testValue.indexOf(value) > -1;
  };

  const filterAndSetCampaigns = useCallback(() => {
    if (
      !searchByName
        && !searchByDate
        && !searchByStatus
        && !searchByCity
    ) {
      setCampaigns(rawCampaigns);
    }

    // Lógica de filtro
    const filteredCampaigns = rawCampaigns.filter((campaign) => {
      let shouldShow = true;

      // Filtro por nombre
      if (
        searchByName
          && !hasMatch(searchByName, campaign.nombre)
      ) {
        shouldShow = false;
      }

      // Filtro por status
      if (
        shouldShow
          && searchByStatus
          && campaign.estado !== searchByStatus.toLocaleUpperCase()
      ) {
        shouldShow = false;
      }

      // Filtro por fecha
      if (
        shouldShow
          && searchByDate
          && !hasMatch(searchByDate, campaign.inicio)
      ) {
        shouldShow = false;
      }

      if (
        shouldShow
          && searchByCity
          && !hasMatch(searchByCity, campaign.ciudad)
      ) {
        shouldShow = false;
      }



      return shouldShow;
    });

    setCampaigns(filteredCampaigns);
  }, [rawCampaigns, searchByName, searchByDate, searchByStatus, searchByCity]);

  useEffect(() => {
    fetchCampanas();
    fetchCampaigns();
  }, [fetchCampaigns]);

  useEffect(() => {
    filterAndSetCampaigns();
  }, [rawCampaigns, searchByName, searchByDate, searchByStatus,searchByCity]);



  return (
    <>
    
      <h4 className="title is-4">Campañas</h4>
      <p className='mb-5'>Listado de todas las campañas</p>
        
        <div className="tabs">
          <ul className='is-left'>
            <li >
              <Link className='pl-0' to="/" onClick={(e) => e.preventDefault()}>Listado</Link>
            </li>
          </ul>
          {/* <ul className='is-right'> 
            <li >
              <Link className='pr-0' to="/" onClick={(e) => e.preventDefault()}>Crear Campaña</Link>
            </li>
          </ul> */}
        </div>

        <div className="columns">
          <div className="column">
            <div className="card">
              <div className="card-content">
                <h4 className="title is-4">Buscar Campañas</h4>
                <div className='columns'>

                  <div className='column'>
                    <p className="has-text-weight-bold" style={styleTituloCard}>NOMBRE DE CAMPAÑA</p>
                    <div className="field control has-icons-right">
                        <input className="input" type="text" value={searchByName} onChange={nameOnChange} />
                        <span className="icon is-small is-right">
                          <FontAwesomeIcon icon={faMagnifyingGlass} />
                        </span>
                    </div>
                  </div>

                  <div className="column">
                    <p className="has-text-weight-bold" style={styleTituloCard}>ESTADO DE CAMPAÑA</p>
                    <div className='field control has-icons-right'>
                      <select className='input' onChange={statusOnChange}>
                        <option value=''>Todos</option>
                        <option value='ACTIVA'>Activa</option>
                        <option value='TERMINADA'>Terminada</option>
                        <option value='INACTIVA'>Inactiva</option>
                      </select>
                      <span className="icon is-small is-right">
                        <FontAwesomeIcon icon={faChevronDown} />
                      </span>
                      {/* <input className="input" type="text" value={searchByStatus} onChange={statusOnChange} />
                      <span className="icon is-small is-right">
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                      </span> */}
                    </div>
                  </div>
                  <div className="column">
                    <p className="has-text-weight-bold" style={styleTituloCard}>CIUDAD</p>
                    <div className='field control has-icons-right'>
                      <select className='input' onChange={cityOnChange}>
                   
                        <option value="">Todas</option>
                        <option value="LEON">LEON</option>
                        <option value="MTY">MTY</option>
                        <option value="QRO">QRO</option>
                        <option value="SLP">SLP</option>
                        <option value="GDL">GDL</option>
                        <option value="AGS">AGS</option>

                      </select>
                      <span className="icon is-small is-right">
                        <FontAwesomeIcon icon={faChevronDown} />
                      </span>
                      {/* <input className="input" type="text" value={searchByStatus} onChange={statusOnChange} />
                      <span className="icon is-small is-right">
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                      </span> */}
                    </div>
                  </div>

                  <div className="column">
                    <p className="has-text-weight-bold" style={styleTituloCard}>FECHA DE ALTA</p>
                    <div className='field control has-icons-right'>
                      <DatePicker
                        className='input'
                        dateFormat="dd/MM/yyyy"
                        selected={datePickerInfo} 
                        onChange={dateOnChange}
                      />
                      {/* <input className="input" type="date" value={searchByDate} onChange={dateOnChange} /> */}
                      <span className="icon is-small is-right">
                        <FontAwesomeIcon icon={faCalendarDays} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="card">
          <div className="card-content">
            {
              loading ? <Spinner /> : (
              <>
                <div className="table-container">
              {
                campaigns.length === 0
                  ? (
                    <div style={{ textAlign: 'center' }}>
                      No encontramos campañas con estos parámetros, por favor cambia tus filtros.
                    </div>
                  ) : (
                    <table className='table is-striped  is-fullwidth is-narrow' style={estiloSinSalto}>
                      <thead>
                        <tr>
                          <th className='is-hidden-tablet'/>
                          <th className='is-hidden-mobile'>ID</th>
                          <th className='is-hidden-desktop-only is-hidden-touch'>Cliente</th>
                          <th>Campaña</th>
                          <th>Ciudad</th>
                          <th className='is-hidden-tablet-only is-hidden-touch'>Fecha Inicio</th>
                          <th className='is-hidden-tablet-only is-hidden-touch'>Fecha Fin</th>
                          <th className='is-hidden-mobile'>Días</th>
                          <th className='is-hidden-desktop-only is-hidden-touch'>Imp. Prog.</th>
                          <th className='is-hidden-mobile'>$</th>
                          {/* <th className='is-hidden-mobile'>Presupuesto</th> */}
                          {/* <th className='is-hidden-desktop-only is-hidden-touch'>Material</th>
                          <th className='is-hidden-tablet-only is-hidden-touch'>Versión</th> */}
                          <th>Estado</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {campaignsList}
                      </tbody>
                    </table>
                  )
              }

                </div>

                <Pages logsPerPage={logsPerPage} allLogs={totalRegistros} pages={pages} currentPage={currentPage} loading={loading} />  
              </>  
          
              )
              }
          </div>
        </div>
    </>
  );
}

export default Campaigns;
