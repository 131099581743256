// React
import React from 'react';
import NumberFormat from 'react-number-format';
import Spinner from '../Spinner/Spinner';
function CardSmall({ title, textPrimary, textSecundary,prefijo,sufijo,isLoading }) {

    return (
        <>
        <div className="card style-card">
            <div className="card-content">
                <p className="has-text-weight-bold style-title-card">{ title.toUpperCase() }</p>
                { isLoading ? <Spinner /> :<> 
                <div className='is-flex is-align-items-center'>
                    <p className='style-text-primary'><NumberFormat
                            value={textPrimary}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={prefijo}
                            suffix={sufijo}
                            renderText={(value, props) =>value}/>
                    <span className='mr-3'></span> <span className='style-text-secundary'>{textSecundary}</span></p>
                </div>
                </>}
            </div>
        </div>
        </>
    );
}

export default CardSmall;