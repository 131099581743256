import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { http } from '../../helpers/http';
import { optionsDate, optionsCurrency } from '../../helpers/formatter';
import Spinner from '../Spinner/Spinner';
import { estiloElementoLista, estiloLista } from '../../helpers/globals';
import NumberFormat from 'react-number-format';

// Components
import CardMedium from '../Card/CardMedium';

function General() {
  const initialState = {
    active: 0,
    bonuses: '',
    client: '',
    days: 0,
    dayse: 0,
    state: '',
    f1: '',
    f2: '',
    f3: '',
    date: '',
    endD: '',
    id_camp: 0,
    id_client: 0,
    startD: '',
    name: '',
    order: '',
    weight: 0,
    budget: 0,
    seg_days: '',
    seg_age: '',
    seg_gender: '',
    seg_schedule: '',
    t_impacts: 0,
    t_impressions: 0,
    t_imp_days: 0,
    t_users: 0,
    url: null,
    version: '',
    material: '',	
  };

  const [loading, setLoading] = useState(false);
  const [campaignInfo, setCampaignInfo] = useState(initialState);
  const [isMobile, setIsMobile] = useState(false);
  const [screenWidth, setScreenWidth] = useState(screen.width);

  useEffect(() => {

    if (screenWidth < 500) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }

  }, [screenWidth]);

  window.addEventListener("resize", e => {
    setScreenWidth(e.target.window.screen.width);
  });

  const styleTituloCard = {
    color: '#999',
    fontSize: '14px',
    marginBottom: '5px',
  };

  // Trae la información según el ID de la campalla que se pasa por params
  const { id: campaignId } = useParams();

  const fetchCampaignInfo = useCallback(async () => {
    setLoading(true);
    try {
      const response = await http.get('/records/lista_camp', {
        params: {
          id_camp: campaignId,
        },
      });

      const { data } = response;

      if (!data?.data?.[0]) {
        setCampaignInfo(initialState);
        return;
      }
      // Aquì se hace pasan las fechas y horas que responde el endpoint por un formato
      // con el método Intl para que todas las muestre de la misma manera.
      // (Esta parte está conectada con el archivo helpers/formatter.js)
      const formattedStartDate = new Intl.DateTimeFormat('es-MX', optionsDate).format(new Date(data.data[0]?.inicio));
      const formattedEndDate = new Intl.DateTimeFormat('es-MX', optionsDate).format(new Date(data.data[0]?.fin));
      const formattedBudget = new Intl.NumberFormat('es-MX', optionsCurrency).format(new Date(data.data[0]?.presupuesto));

      setCampaignInfo({
        active: data.data[0].activa || '',
        bonuses: data.data[0].bonificaciones || '',
        client: data.data[0].cliente || '',
        version:  data.data[0].version || '',
        material: data.data[0].material || '',
        days: data.data[0].dias || 0,
        dayse: data.data[0].dias_efectivos_totales || 0,
        state: data.data[0].estado || '',
        f1: data.data[0].f1 || '',
        f2: data.data[0].f2 || '',
        f3: data.data[0].f3 || '',
        date: data.data[0].fecha || '',
        endD: formattedEndDate || '',
        id_camp: data.data[0].id_camp || 0,
        id_client: data.data[0].id_client || 0,
        startD: formattedStartDate || '',
        name: data.data[0].nombre || '',
        order: data.data[0].orden || '',
        weight: data.data[0].peso || 0,
        budget: formattedBudget || 0,
        seg_days: data.data[0].seg_dias || '',
        seg_age: data.data[0].seg_edad || '',
        seg_gender: data.data[0].seg_genero?.toLocaleUpperCase() || '',
        seg_schedule: data.data[0].seg_horarios || '',
        t_impacts: data.data[0].total_impactos || 0,
        t_impressions: data.data[0].total_impresiones || 0,
        t_imp_days: data.data[0].total_impresiones_dia || '',
        t_users: data.data[0].total_usuarios || 0,
        url: data.data[0].url || null,
        documentos: data.documentos || [],
        ciudad: data.data[0].ciudad || '',
      });
    } catch (err) {
      // console.error(err);
    } finally {
      setLoading(false);
    }
  }, [campaignId]);

  useEffect(() => {
    fetchCampaignInfo();
  }, [fetchCampaignInfo]);

  const genderList = [
    'TODOS',
    'MASCULINO',
    'FEMENINO',
  ];

  const ageList = [
    'TODOS',
    '-18',
    '18-24',
    '25-34',
    '35-44',
    '45-54',
    '55-64',
    '65+',
  ];
  const daysList = [
    'TODOS',
    'LUN',
    'MAR',
    'MIE',
    'JUE',
    'VIE',
    'SAB',
    'DOM',
  ];
  const scheduleList = [
    'TODOS',
    '6-9AM',
    '9AM-12PM',
    '12-4PM',
    '4-8PM',
    '8-11PM',
  ];

  // Esta funcionalidad lo que hace es "testear" la información que manda el endpoint
  // para entonces "pintar" los valores de la tabla derecha. Es decir, el valor que manda el backend
  // es el que aparece en color verde en la tabla.
  // eslint-disable-next-line max-len
  const renderListElements = useCallback((testValue, possibleValues) => {

    return(
      <ul className="options">
        {possibleValues.map((possible) => {
          const isCurrent = testValue.indexOf(possible) > -1;
      
          return (
              <li className={`${isCurrent ? 'on' : ''}`} key={`group_element_${possible}`}>
                {possible}
              </li>
          );
        })}
      </ul>
    );

  
  }, []);

  const renderList = useCallback((currentGender, possibleValues) => {
    return renderListElements(currentGender, possibleValues);
  }, [renderListElements]);

  if (loading) {
    return <Spinner />;
  }

  function numberFormat( value ) {

    return <NumberFormat
      value={ value }
      displayType={'text'}
      thousandSeparator={true}
      renderText={(value, props) => value} 
    />
    
  }

  const dataInfoGeneral = [
    {
      name: 'Nombre de la Campaña:',
      value: campaignInfo.name
    },
    {
      name: 'Versión:',
      value: campaignInfo.version
    },
    {
      name: 'Material:',
      value: campaignInfo.material
    },
    {
      name: 'Orden de Transmisión:',
      value: campaignInfo.order
    },
    {
      name: 'Ciudad:',
      value: campaignInfo.ciudad
    },
    {
      name: 'Fecha de Inicio:',
      value: campaignInfo.startD
    },
    {
      name: 'Fecha de Fin:',
      value: campaignInfo.endD
    },
    {
      name: 'Días calendario:',
      value: campaignInfo.days
    },{
      name: 'Días efectivos:',
      value: campaignInfo.dayse
    },
    {
      name: 'Funcionalidad Pantalla 1:',
      value: campaignInfo.f1
    },
    {
      name: 'Funcionalidad Pantalla 2:',
      value: campaignInfo.f2
    },
    {
      name: 'Funcionalidad Pantalla 3:',
      value: campaignInfo.f3
    },
    {
      name: 'Total de Impactos Estimados (Conexiones):',
      value: numberFormat(campaignInfo.t_impacts)
    },
    {
      name: 'Total de Impresiones Estimadas:',
      value: numberFormat(campaignInfo.t_impressions)
    },
    {
      name: 'Total de Usuarios Impactados Estimados por día (conexiones):',
      value: numberFormat(campaignInfo.t_users)
    },
    {
      name: 'Total de Impresiones Estimadas Diarias:',
      value: numberFormat(campaignInfo.t_imp_days)
    },
    {
      name: 'Presupuesto:',
      value: campaignInfo.budget
    },
    {
      name: 'Bonificaciones:',
      value: numberFormat(campaignInfo.bonuses)
    },
  ];

  console.log(campaignInfo);

  return (

    <><div className="columns is-multiline" id='info' >
      <div className="column is-full-touch">
        <CardMedium data={dataInfoGeneral} title='Información General'  styleCardTitulo={{}} titleClass='title is-5' isAdjuntar={true} dataPDFds={campaignInfo.documentos} />
      </div>
      <div className="column is-full-touch">
        <div className="card">
          <div className="card-content">
            <h4 className="title is-4">Segmentación de Audiencias</h4>

            <div>
              <div className="switch is-size-7-mobile mb-5">
                <p className="has-text-weight-bold mb-0" style={styleTituloCard}>GÉNERO</p>
                  {renderList(campaignInfo.seg_gender, genderList)}
              </div>
              <div className="switch is-size-7-mobile mb-5">
                <p className="has-text-weight-bold mb-0" style={styleTituloCard}>GRUPO DE EDAD</p>
                  {/* {isMobile ?
                  <>
                  {renderList(campaignInfo.seg_age, ageList.slice(0, 4))}
                  {renderList(campaignInfo.seg_age, ageList.slice(4, 8))}
                  </>  
                  : renderList(campaignInfo.seg_age, ageList) } */}
                  {renderList(campaignInfo.seg_age, ageList)}
              </div>
              <div className="switch is-size-7-mobile mb-5">
                <p className="has-text-weight-bold mb-0" style={styleTituloCard}>SEGMENTACIÓN DE DÍAS</p>
                {/* {isMobile ?
                  <>
                  {renderList(campaignInfo.seg_days, daysList.slice(0, 4))}
                  {renderList(campaignInfo.seg_days, daysList.slice(4, 8))}
                  </>  
                  : renderList(campaignInfo.seg_days, daysList) } */}
                  {renderList(campaignInfo.seg_days, daysList)}
              </div>
              <div className="switch is-size-7-mobile mb-5">
                <p className="has-text-weight-bold mb-0" style={styleTituloCard}>SEGMENTACIÓN DE HORARIOS</p>
                {/* {isMobile ?
                  <>
                  {renderList(campaignInfo.seg_schedule, scheduleList.slice(0, 3))}
                  {renderList(campaignInfo.seg_schedule, scheduleList.slice(3, 6))}
                  </>  
                  : renderList(campaignInfo.seg_schedule, scheduleList) } */}
                  {renderList(campaignInfo.seg_schedule, scheduleList)}
              </div>
            </div>
        </div>
      </div> 
      </div>
    </div></>
  );
}

export default General;
