import React, { useEffect, useState, useCallback } from 'react';
import { optionsDateTime } from '../../../helpers/formatter';
import Pages from './Pages';
import { estiloLista } from '../../../helpers/globals';
import { optionsDate } from '../../../helpers/formatter';
import {FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArrowDown, faCircleChevronDown } from '@fortawesome/free-solid-svg-icons'
import { http } from '../../../helpers/http';
import Spinner from '../../Spinner/Spinner';

// Icons
import { faMagnifyingGlass, faCalendarDays, faCircleChevronUp } from '@fortawesome/free-solid-svg-icons';

const styleCardTable = {
  // whiteSpace: 'break-spaces',
  width: '100px',
  whiteSpace: 'pre-wrap',
  whiteSpace: '-moz-pre-wrap',
  whiteSpace: '-pre-wrap',
  whiteSpace: '-o-pre-wrap',
  wordWrap: 'break-word',
};

function LogTable({
  campaignId, start, end, selectedScreen,totalRegistros
}) {
  const [loading, setLoading] = useState(false);
  // Aquí lo que se hace es cambiar "parsear" el ID a base 64 para esconderlo
  // en la query para la lìnea 20 de este componente.
  const encodedId = btoa(campaignId);
  const estiloSinSalto = {
    whiteSpace: 'nowrap',	
  };
  // Esta es la paginación de la lista de registros.
  const [currentPage, setCurrentPage] = useState(1);
  const [logsPerPage] = useState(30);
  const [tableInfo, setTableInfo] = useState([]);
  const currentLogs = tableInfo;

  const numerodePaginas=Math.ceil(totalRegistros / logsPerPage);
  //const numerodePaginas=100;
  const [isCollapse, setIsCollapse] = useState(false);


  const pages = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  // Descarga un archivo .CSV con los primeros 1000 registros de esa campaña.
  const handleClick = () => {
    const fechainicio = new Intl.DateTimeFormat('es-MX', optionsDate).format(start);
    const yyyymmdd_Inicio = fechainicio.split('/').reverse().join('-');
    const fechafin = new Intl.DateTimeFormat('es-MX', optionsDate).format(end);
    const yyyymmdd_Final = fechafin.split('/').reverse().join('-');

    

    window.open(`https://exporters.wiwi.services/kpis/descarga_registros?id_camp=${encodedId}&pantalla=${selectedScreen}&inicio=${yyyymmdd_Inicio}&fin=${yyyymmdd_Final}`);
  };

  const toggleCollapseTable = (evt, id) => {

    document.querySelector(`.data-${id}`).classList.toggle('is-hidden-mobile');

    // setIsCollapse(isCollapse => !isCollapse);

  }



  async function fetchTableInfo(){
    setTableInfo([]);
    setLoading(true);
    const fechainicio = new Intl.DateTimeFormat('es-MX', optionsDate).format(start);
    const yyyymmdd_Inicio = fechainicio.split('/').reverse().join('-');
    const fechafin = new Intl.DateTimeFormat('es-MX', optionsDate).format(end);
    const yyyymmdd_Final = fechafin.split('/').reverse().join('-');

    try {
      const response = await http.get('/kpis/lista_registros', {
        params: {
          id_camp: campaignId,
          cap: 1,
          inicio: yyyymmdd_Inicio,
          fin: yyyymmdd_Final,
          pantalla: selectedScreen,
          pagina: currentPage
        },
      });
      
     
      
      setTableInfo(response.data.data);
      setLoading(false);
      
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    
      fetchTableInfo();
  }, [currentPage,start,end,selectedScreen]);



  return (
    <><div className="p-1"><div className="table-container">
      {tableInfo.length === 0 || loading ? (
        
          <div style={{ textAlign: 'center' }}>
            <Spinner/>
          </div>
        ) : (
          <table className="table is-striped  is-fullwidth is-narrow" style={estiloSinSalto}>
            <thead>
              <tr>
                <th className='is-hidden-tablet'/>
                <th className='is-hidden-mobile'>#</th>
                <th>ID</th>
                <th className='is-hidden-widescreen-only is-hidden-desktop-only is-hidden-touch'>Día y Hora</th>
                <th className='is-hidden-desktop-only is-hidden-touch'>Nodo</th>
                <th className='is-hidden-desktop-only is-hidden-touch'>ID_Usuario</th>
                <th className='is-hidden-widescreen-only is-hidden-desktop-only is-hidden-touch'>Cliente</th>
                <th>Campaña</th>
                <th className='is-hidden-widescreen-only is-hidden-desktop-only is-hidden-touch'>Versión</th>
                <th className='is-hidden-mobile'>Material</th>
                <th className='is-hidden-desktop-only is-hidden-touch'>Nº Pant.</th>
                <th className='is-hidden-mobile'>Elementos</th>
              </tr>
            </thead>
            <tbody>
              {currentLogs.map((log, index) => (
                <>
                <tr key={index}>
                  <td className='is-hidden-tablet custom-chevron-icon fas fa-lg py-2' onClick={ evt => toggleCollapseTable(evt, log.id)}>
                    <FontAwesomeIcon icon={ faCircleChevronDown } />
                  </td>
                  <td className='is-hidden-mobile'>{log.idgenerico}</td>
                  <td>{log.id}</td>
                  <td className='is-hidden-widescreen-only is-hidden-desktop-only is-hidden-touch'>{new Intl.DateTimeFormat('es-MX', optionsDateTime).format(new Date(log.fecha))}</td>
                  <td className='is-hidden-desktop-only is-hidden-touch'>{log.nodo}</td>
                  <td className='is-hidden-desktop-only is-hidden-touch'>{log.id_usuario}</td>
                  <td className='is-hidden-widescreen-only is-hidden-desktop-only is-hidden-touch'>{log.name}</td>
                  <td>{log.nombre}</td>
                  <td className='is-hidden-widescreen-only is-hidden-desktop-only is-hidden-touch'>{log.version}</td>
                  <td className='is-hidden-mobile'>{log.material}</td>
                  <td className='is-hidden-desktop-only is-hidden-touch'>{log.pantalla}</td>
                  <td className='is-hidden-mobile'>{log.elemento}</td>
                </tr>
                <tr className={`is-hidden-mobile is-hidden-tablet data-${log.id}`}>
                  <td colSpan={3}>
                    <div className='is-flex is-justify-content-space-between'>
                      <div className='column p-0 has-text-weight-bold'>
                        #
                      </div>
                      <div className='column p-0'>
                        <span>{log.idgenerico}</span>
                      </div>
                    </div>
                    <div className='is-flex is-justify-content-space-between'>
                      <div className='column p-0 has-text-weight-bold'>
                        ID
                      </div>
                      <div className='column p-0'>
                        <span>{log.id}</span>
                      </div>
                    </div>
                    <div className='is-flex is-justify-content-space-between'>
                      <div className='column p-0 has-text-weight-bold'>
                        Día y Hora
                      </div>
                      <div className='column p-0 style-card-table'>
                        {new Intl.DateTimeFormat('es-MX', optionsDateTime).format(new Date(log.fecha))}
                      </div>
                    </div>
                    <div className='is-flex is-justify-content-space-between'>
                      <div className='column p-0 has-text-weight-bold'>
                        Nodo
                      </div>
                      <div className='column p-0 style-card-table'>
                        {log.nodo}
                      </div>
                    </div>
                    <div className='is-flex is-justify-content-space-between'>
                      <div className='column p-0 has-text-weight-bold'>
                        ID_Usuario
                      </div>
                      <div className='column p-0 style-card-table'>
                        {log.id_usuario}
                      </div>
                    </div>
                    <div className='is-flex is-justify-content-space-between'>
                      <div className='column p-0 has-text-weight-bold'>
                        Cliente
                      </div>
                      <div className='column p-0 style-card-table'>
                        {log.name}
                      </div>
                    </div>
                    <div className='is-flex is-justify-content-space-between'>
                      <div className='column p-0 has-text-weight-bold'>
                        Campaña
                      </div>
                      <div className='column p-0'>
                        <span className='style-card-table'>{log.nombre}</span>
                      </div>
                    </div>
                    <div className='is-flex is-justify-content-space-between'>
                      <div className='column p-0 has-text-weight-bold'>
                        Versión
                      </div>
                      <div className='column p-0'>
                        <span>{log.version}</span>
                      </div>
                    </div>
                    <div className='is-flex is-justify-content-space-between'>
                      <div className='column p-0 has-text-weight-bold'>
                        Material
                      </div>
                      <div className='column p-0'>
                        <span>{log.material}</span>
                      </div>
                    </div>
                    <div className='is-flex is-justify-content-space-between'>
                      <div className='column p-0 has-text-weight-bold'>
                        Nº Pant.
                      </div>
                      <div className='column p-0'>
                        <span>{log.pantalla}</span>
                      </div>
                    </div>
                    <div className='is-flex is-justify-content-space-between'>
                      <div className='column p-0 has-text-weight-bold'>
                        Elementos
                      </div>
                      <div className='column p-0 style-card-table'>
                        {log.elemento}
                      </div>
                    </div>
                  </td>
                </tr>
                </>
              ))}
            </tbody>
          </table>
        )}
    </div>

    
       
       
       
    
   
    {
        tableInfo.length === 0 ? (
            <>
            </>
          )
            : (
              <>
              <Pages logsPerPage={logsPerPage} allLogs={totalRegistros} pages={pages} currentPage={currentPage} loading={loading} /> 
              
              <button className="button hasHover" style={{height: 'auto', paddingTop: '14px', paddingBottom: '14px', paddingLeft: '16px', paddingRight: '16px'}} onClick={handleClick}>
              <span className="icon">
                <FontAwesomeIcon icon={faFileArrowDown} />
              </span>
              <span style={{fontSize:'16px', fontWeight: 700}}>Descargar Datos</span>
            </button>
            </>
            )
          }
    </div>
    </>
      
    
  );
}

export default LogTable;
