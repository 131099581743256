import React from 'react';

function DashboardIcon(props) {
  return (
    <svg viewBox="0 0 50 50" height={20} width={20} fill="currentColor" {...props}>
      <path
        d="M46.646,39.524H5.064V8.766c0-0.943-0.765-1.708-1.708-1.708c-0.944,0-1.709,0.765-1.709,1.708v32.469
            c0,0.944,0.765,1.708,1.709,1.708h43.29c0.942,0,1.708-0.764,1.708-1.708S47.588,39.524,46.646,39.524z"
        stroke="currentColor"
      />
      <path
        d="M8.361,36.81c0.437,0,0.875-0.167,1.208-0.5l13.425-13.426l6.932,6.931c0.667,0.668,1.748,0.668,2.416,0l10.782-10.783
            c0.005,0.936,0.758,1.696,1.694,1.696c0.005,0,0.009,0,0.014,0c0.937,0,1.701-0.751,1.708-1.691l0.029-4.219
            c0.005-0.488-0.199-0.954-0.56-1.281c-0.362-0.326-0.845-0.483-1.333-0.43l-4.531,0.492c-0.938,0.103-1.615,0.945-1.515,1.884
            c0.097,0.873,0.835,1.513,1.696,1.516l-9.192,9.192l-6.931-6.933c-0.667-0.665-1.75-0.665-2.417,0.002L7.153,33.893
            c-0.667,0.667-0.667,1.749,0,2.417C7.486,36.643,7.924,36.81,8.361,36.81z"
        stroke="currentColor"
      />
    </svg>
  );
}

export default DashboardIcon;
