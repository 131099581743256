import React, { useState, useEffect } from 'react';
import { estiloLista } from '../../../helpers/globals';
import NumberFormat from 'react-number-format';
import Spinner from '../../Spinner/Spinner';


function KPI({ info, loading,impresiones,loadingImpresiones,impresionesGenero,loadingGenero }) {

  const styleTituloCard = {
    color: '#999',
    fontSize: '14px',
  };

  const styleCard = {
    height: `100%`
  };

  const [infoKPI, setInfoKPI] = useState(info);
  //const gender = [impresionesGenero.femenino, infoKPI.masculino];
  const age = [
    infoKPI.ageU18,
    infoKPI.age18,
    infoKPI.age25,
    infoKPI.age35,
    infoKPI.age45,
    infoKPI.age50,
    infoKPI.age55,
    infoKPI.ageU65,
  ];

  const percentage = (arr) => {
    let total = 0;
    const result = [];
    for (let i = 0; i < arr.length; i += 1) {
      total += arr[i];
    }
    for (let i = 0; i < arr.length; i += 1) {
      const p = (arr[i] * 100) / total;
      if (Number.isNaN(p)) {
        result.push(0);
      } else {
        result.push(p);
      }
    }
    return result;
  };

  useEffect(() => {
  
    setInfoKPI(info);
  }, [info]);



 



  return (
    <>
    <div className='columns is-multiline is-mobile'>

      <div className="column is-one-quarter-desktop is-half-touch">
        <div className="card" style={styleCard}>
          <div className="card-content has-text-centered">

            <p className="has-text-weight-bold is-size-7-mobile" style={styleTituloCard}>
              TOTAL<br />IMPRESIONES
            </p>
            {
              loadingImpresiones ? (
                <Spinner/>
              ) : (
                <span className="is-size-4 is-size-5-mobile">
                <NumberFormat
                  value={impresiones.impresiones}
                  displayType={'text'}
                  thousandSeparator={true}
                  renderText={(value, props) => <strong {...props}>{value}</strong>} />
              </span>
              )
            }

         
          </div>
        </div>
      </div>

      <div className="column is-one-quarter-desktop is-half-touch">
        <div className="card" style={styleCard}>
          <div className="card-content has-text-centered">

            <p className="has-text-weight-bold is-size-7-mobile" style={styleTituloCard}>
              TOTAL U.<br />IMPACTADOS
            </p>

            {
              loadingImpresiones ? (
                <Spinner/>
              ) : (
                 <span className="is-size-4 is-size-5-mobile">
              <NumberFormat
                value={impresiones.usuarios_impactados}
                displayType={'text'}
                thousandSeparator={true}
                renderText={(value, props) => <strong {...props}>{value}</strong>} />
            </span>
              )
            }


           
          </div>
        </div>
      </div>

      <div className="column is-one-quarter-desktop is-half-touch">
        <div className="card" style={styleCard}>
          <div className="card-content has-text-centered">
            <p className="has-text-weight-bold is-size-7-mobile" style={styleTituloCard}>
              USUARIOS<br />ÚNICOS
            </p>


            {
              loadingImpresiones ? (
                <Spinner/>
              ) : (
                <span className="is-size-4 is-size-5-mobile">
                <NumberFormat
                  value={impresiones.unicos}
                  displayType={'text'}
                  thousandSeparator={true}
                  renderText={(value, props) => <strong {...props}>{value}</strong>} />
              </span>
              )
            }

          
          </div>
        </div>
      </div>

      <div className="column is-one-quarter-desktop is-half-touch">
        <div className="card" style={styleCard}>
          <div className="card-content has-text-centered px-0">
            <p className="has-text-weight-bold is-size-7-mobile mb-0" style={styleTituloCard}>
              GÉNERO
            </p>
            <span className="is-size-4 is-size-7-mobile">
              <div className="columns is-mobile">
                <div className="column pr-0">


                <small className='is-size-7-mobile' style={{fontSize:'18px'}}>Mujer</small>
                    <br />

                    {
                      loadingGenero ? (
                        <Spinner/>
                      ) : (
                        <strong>{impresionesGenero?impresionesGenero.femenino+"%":"0%"}</strong>
                      )
                    }


                            
                        </div>
                        <div className="column pl-0">
                        <small className='is-size-7-mobile' style={{fontSize:'18px'}}>Hombre</small>
                            <br />
                    {
                      loadingGenero ? (
                        <Spinner/>
                      ) : (
                        <strong>{impresionesGenero?impresionesGenero.masculino+"%":"0%"}</strong>
                      )
                    }


                  
                </div>
              </div>
            </span>
          </div>
        </div>
      </div>

    </div>
    
    {/* PARTE 2 */}

    <div className="columns">
      <div className="column">
        <div className="card">
          <div className="card-content has-text-centered">
            <p className="has-text-weight-bold" style={styleTituloCard}>
                RANGOS DE EDAD
            </p>

            
            
         
            {
                      loading ? (
                        <Spinner/>
                      ) : (
                        <div className='columns is-multiline'>
                        <div className='column is-half-desktop is-full'>
                          <div className='columns is-multiline is-mobile'>
          
                            {/* COLUMNA MENOS DE 18 AÑOS */}
                            <div className="column is-half-mobile">
                              <small style={{fontSize:'18px'}}>-18</small>
                              <br />
                              <span className="is-size-4">
                                  <NumberFormat
                                      value={infoKPI.ageU18}
                                      displayType={'text'}
                                      thousandSeparator={true}
                                      renderText={(value, props) => <strong {...props}>{value}</strong>}
                                  />
                              </span>
                              <br />
                              <span>{typeof (percentage(age)[0].toFixed(1)) === 'string' ? `${percentage(age)[0].toFixed(1)}%` : '0%'}</span>
                            </div>
          
                            {/* COLUMNA 18 AÑOS */}
                            <div className="column is-half-mobile">
                              <small style={{fontSize:'18px'}}>18+</small>
                              <br />
                              <span className="is-size-4">
                                  <NumberFormat
                                      value={infoKPI.age18}
                                      displayType={'text'}
                                      thousandSeparator={true}
                                      renderText={(value, props) => <strong {...props}>{value}</strong>}
                                  />
                              </span>
                              <br />
                              <span>{typeof (percentage(age)[1].toFixed(1)) === 'string' ? `${percentage(age)[1].toFixed(1)}%` : '0%'}</span>
                            </div>
          
                            {/* COLUMNA 25 AÑOS */}
                            <div className="column is-half-mobile">
                              <small style={{fontSize:'18px'}}>25+</small>
                              <br />
                              <span className="is-size-4">
                                  <NumberFormat
                                      value={infoKPI.age25}
                                      displayType={'text'}
                                      thousandSeparator={true}
                                      renderText={(value, props) => <strong {...props}>{value}</strong>}
                                  />
                              </span>
                              <br />
                              <span>{typeof (percentage(age)[2].toFixed(1)) === 'string' ? `${percentage(age)[2].toFixed(1)}%` : '0%'}</span>
                            </div>
                            
                            {/* COLUMNA 35 AÑOS */}
                            <div className="column is-half-mobile">
                              <small style={{fontSize:'18px'}}>35+</small>
                              <br />
                              <span className="is-size-4">
                                  <NumberFormat
                                      value={infoKPI.age35}
                                      displayType={'text'}
                                      thousandSeparator={true}
                                      renderText={(value, props) => <strong {...props}>{value}</strong>}
                                  />
                              </span>
                              <br />
                              <span>{typeof (percentage(age)[3].toFixed(1)) === 'string' ? `${percentage(age)[3].toFixed(1)}%` : '0%'}</span>
                            </div>
          
                          </div>
                        </div>
                        {/* PARTE INTERNA 2 */}
                        <div className='column is-half-desktop is-full'>
                          <div className='columns is-multiline is-mobile'>
          
                            {/* COLUMNA 45 AÑOS */}
                            <div className="column is-half-mobile">
                              <small style={{fontSize:'18px'}}>45+</small>
                              <br />
                              <span className="is-size-4">
                                  <NumberFormat
                                      value={infoKPI.age45}
                                      displayType={'text'}
                                      thousandSeparator={true}
                                      renderText={(value, props) => <strong {...props}>{value}</strong>}
                                  />
                              </span>
                              <br />
                              <span>{typeof (percentage(age)[4].toFixed(1)) === 'string' ? `${percentage(age)[4].toFixed(1)}%` : '0%'}</span>
                            </div>
          
          
                            {/* COLUMNA 50 AÑOS */}
                            <div className="column is-half-mobile">
                              <small style={{fontSize:'18px'}}>50+</small>
                              <br />
                              <span className="is-size-4">
                                  <NumberFormat
                                      value={infoKPI.age50}
                                      displayType={'text'}
                                      thousandSeparator={true}
                                      renderText={(value, props) => <strong {...props}>{value}</strong>}
                                  />
                              </span>
                              <br />
                              <span>{typeof (percentage(age)[5].toFixed(1)) === 'string' ? `${percentage(age)[5].toFixed(1)}%` : '0%'}</span>
                            </div>
          
          
          
          
                            {/* COLUMNA 55 AÑOS */}
                            <div className="column is-half-mobile">
                              <small style={{fontSize:'18px'}}>55+</small>
                              <br />
                              <span className="is-size-4">
                                  <NumberFormat
                                      value={infoKPI.age55}
                                      displayType={'text'}
                                      thousandSeparator={true}
                                      renderText={(value, props) => <strong {...props}>{value}</strong>}
                                  />
                              </span>
                              <br />
                              <span>{typeof (percentage(age)[6].toFixed(1)) === 'string' ? `${percentage(age)[6].toFixed(1)}%` : '0%'}</span>
                            </div>
          
                            {/* COLUMNA 65 AÑOS */}
                            <div className="column is-half-mobile">
                              <small style={{fontSize:'18px'}}>65+</small>
                              <br />
                              <span className="is-size-4">
                                <NumberFormat
                                  value={infoKPI.ageU65}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  renderText={(value, props) => <strong {...props}>{value}</strong>}
                                />
                              </span>
                              <br />
                              <span>{typeof (percentage(age)[7].toFixed(1)) === 'string' ? `${percentage(age)[7].toFixed(1)}%` : '0%'}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      )
                    }


          </div>
        </div>



        {/* <label>Rangos de Edad</label>
        <ul style={estiloLista}>
          <li>
            <span className="text-emphasis">
              <small>-18</small>
              <NumberFormat
          value={infoKPI.ageU18}
          displayType={'text'}
          thousandSeparator={true}
          renderText={(value, props) => <strong {...props}>{value}</strong>}
          />
              <span>{typeof (percentage(age)[0].toFixed(1)) === 'string' ? `${percentage(age)[0].toFixed(1)}%` : '0%'}</span>
            </span>
          </li>
          <li>
            <span className="text-emphasis">
              <small>18+</small>
              <NumberFormat
          value={infoKPI.age18}
          displayType={'text'}
          thousandSeparator={true}
          renderText={(value, props) => <strong {...props}>{value}</strong>}
          />
            
              <span>{typeof (percentage(age)[1].toFixed(1)) === 'string' ? `${percentage(age)[1].toFixed(1)}%` : '0%'}</span>
            </span>
          </li>
          <li>
            <span className="text-emphasis">
              <small>25+</small>
              <NumberFormat
                value={infoKPI.age25}
                displayType={'text'}
                thousandSeparator={true}
                renderText={(value, props) => <strong {...props}>{value}</strong>}
            />
              
              <span>{typeof (percentage(age)[2].toFixed(1)) === 'string' ? `${percentage(age)[2].toFixed(1)}%` : '0%'}</span>
            </span>
          </li>
          <li>
            <span className="text-emphasis">
              <small>35+</small>
              <NumberFormat
                value={infoKPI.age35}
                displayType={'text'}
                thousandSeparator={true}
                renderText={(value, props) => <strong {...props}>{value}</strong>}
              />
              
              <span>{typeof (percentage(age)[3].toFixed(1)) === 'string' ? `${percentage(age)[3].toFixed(1)}%` : '0%'}</span>
            </span>
          </li>
          <li>
            <span className="text-emphasis">
              <small>45+</small>
              <NumberFormat
                value={infoKPI.age45}
                displayType={'text'}
                thousandSeparator={true}
                renderText={(value, props) => <strong {...props}>{value}</strong>}
              />
              <span>{typeof (percentage(age)[4].toFixed(1)) === 'string' ? `${percentage(age)[4].toFixed(1)}%` : '0%'}</span>
            </span>
          </li>
          <li>
            <span className="text-emphasis">
              <small>50+</small>
              <NumberFormat
                value={infoKPI.age50}
                displayType={'text'}
                thousandSeparator={true}
                renderText={(value, props) => <strong {...props}>{value}</strong>}
              />
              <span>{typeof (percentage(age)[5].toFixed(1)) === 'string' ? `${percentage(age)[5].toFixed(1)}%` : '0%'}</span>
            </span>
          </li>
          <li>
            <span className="text-emphasis">
              <small>55+</small>
              <NumberFormat
                value={infoKPI.age55}
                displayType={'text'}
                thousandSeparator={true}
                renderText={(value, props) => <strong {...props}>{value}</strong>}
              />
              <span>{typeof (percentage(age)[6].toFixed(1)) === 'string' ? `${percentage(age)[6].toFixed(1)}%` : '0%'}</span>
            </span>
          </li>
          <li>
            <span className="text-emphasis">
              <small>65+</small>
              <NumberFormat
                value={infoKPI.ageU65}
                displayType={'text'}
                thousandSeparator={true}
                renderText={(value, props) => <strong {...props}>{value}</strong>}
              />
              <span>{typeof (percentage(age)[7].toFixed(1)) === 'string' ? `${percentage(age)[7].toFixed(1)}%` : '0%'}</span>
            </span>
          </li>
        </ul> */}
      </div>  
    </div></>

  );
}

export default KPI;
