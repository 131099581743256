import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/auth';

function Login() {
  const auth = useAuth();
  const navigate = useNavigate();

  const [input, setInput] = useState({
    mail: '',
    pass: '',
  });

  const handleChange = ({ target }) => {
    setInput({
      ...input,
      [target.name]: target.value,
    });
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    const { mail, pass } = input;
    const isOk = await auth.login(mail, pass);

    if (isOk) {
      navigate('/dashboard');
    } else {
      // eslint-disable-next-line no-alert
      alert(' ⚠️ Verifica que tus datos sean correctos');
    }
  };

  const isFull = () => (!!((input.mail.length > 0 && input.pass.length > 0)));

  return (
    <section className="login">
      <div className="wrapper">
        <span className="logo">Wiwi</span>
        <h1>Wiwi Campaign Manager</h1>
        <h2>Acceso a Clientes</h2>
        <form onSubmit={handleOnSubmit}>
          <div className="row">
            <label>Usuario</label>
            <input type="text" placeholder="Ingrese su nombre de usuario" spellCheck="false" value={input.mail} onChange={handleChange} name="mail" />
          </div>
          <div className="row">
            <label>Contraseña</label>
            <input type="password" value={input.pass} onChange={handleChange} name="pass" spellCheck="false" />
          </div>
          <div className="row">
            <button
              disabled={!isFull()}
              className="submit"
              type="submit"
            >
              Ingresar
            </button>
          </div>
        </form>
      </div>
    </section>
  );
}

export default Login;
