import React, { useState, useEffect } from 'react';
import {
  Chart as CharJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import NumberFormat from 'react-number-format';

CharJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function Impressions({ impressionsInfo }) {
  const [chartInfo] = useState(impressionsInfo);
  const [chartData, setChartData] = useState({
    datasets: [],
  });
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    setChartData({
      labels: ['Totales', 'Promedio'],
      datasets: [
        {
          data: chartInfo,
          backgroundColor: ['rgba(255, 206, 86, 0.2)', 'rgba(75, 192, 192, 0.2)'],
          borderColor: ['rgba(255, 206, 86, 1)', 'rgba(75, 192, 192, 1)'],
          borderWidth: 2,
          borderRadius: 0,
          datalabels: {
            color: '#999',
            anchor: 'end',
            align: 'top',
            formatter: (value) => {
              return  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
          },
        },
      ],
    });
    setChartOptions({
      responsive: true,
      plugins: {
        legend: {
          display: false,
          position: 'bottom',
        },
      },

      scales: {
        y: {
            beginAtZero: true,
            suggestedMin: 0,
            suggestedMax: Math.max(...chartInfo)+(Math.max(...chartInfo)*.1),
        }
      }


    });
  }, [chartInfo[0]]);

  if (!chartInfo[0]) {
    return <>Esta gráfica no está disponible por el momento ❌</>;
  }

  return (
    <>
      <Bar options={chartOptions} data={chartData} width={100} height={100} />
      <ul className="legends ml-0">
        <li>
          <small style={{ background: '#fff5de', border: '2px solid #ffce57' }} />
          <NumberFormat
                  value={chartInfo[0]}
                  displayType={'text'}
                  thousandSeparator={true}
                  renderText={(value, props) => <strong {...props}>{`Totales: ${value}`}</strong>}
                />
         
        </li>
        <li>
          <small style={{ background: '#dbf2f2', border: '2px solid #4bc0c0' }} />
          <NumberFormat
                  value={chartInfo[1]}
                  displayType={'text'}
                  thousandSeparator={true}
                  renderText={(value, props) => <strong {...props}>{`Promedio: ${value}`}</strong>}
                />
        
        </li>
      </ul>
    </>
  );
}

export default Impressions;
