import React from 'react';
import { Routes, Route, Navigate,useLocation } from 'react-router-dom';

import Dashboard from './Dashboard';
import Payments from './Payments';
import Account from './Account';
import Campaigns from './Campaigns';
import { http } from '../helpers/http';
import { useAuth } from '../contexts/auth';
import Campaign from '../components/CampaignComponents/Campaign';
import NavBar from '../components/NavBar/NavBar';
import General from '../components/CampaignComponents/General';
import Preview from '../components/CampaignComponents/Preview';
import Analytics from '../components/CampaignComponents/Analytics';
import Log from '../components/CampaignComponents/Log';
import { useEffect } from 'react';
import { useState } from 'react';

 function Protected() {
  const {
    auth,
  } = useAuth();


  const [campanas, setCampanas] = useState([]);

  const {pathname} = useLocation();
  const splitLocation = pathname.split("/");

  
  const id = splitLocation[2];
  

  useEffect(async () => {
    if (splitLocation[1] === "campaign" && id!=undefined) {
      const response = await http.get('/records/lista_camp', {
        params: {
          id_client: auth.id_client, 
          id_user: auth.id,
        },
      });
      const { data } = response;

      for (let i = 0; i < data.data.length; i++) {
       setCampanas((campanas) => [...campanas, data.data[i].id_camp]);
      }
   }
  }, [splitLocation[1],id]);


  return (
    <>
      <NavBar />
        <div className="container has-padding" style={{backgroundColor:"#f9f9f9"}}>
          <Routes>
            <Route index element={<Dashboard />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="payments" element={<Payments />} />
            <Route path="account" element={<Account />} />
            <Route path="campaigns" element={<Campaigns />} />
            <Route path="campaign" element={<Navigate to="/campaigns" />} />
            {campanas.length>0 ? (
            <Route path="campaign/:id" element={<Campaign />}>
            {/* <Route path="campaign/:id" element={campanas.includes(parseInt(id)) ? <Campaign /> : <Navigate to="/campaigns" />}> */}
              <Route index element={<Navigate to="general" />} />
              <Route path="general" element={<General />} />
              <Route path="preview" element={<Preview />} />
              <Route path="analytics" element={<Analytics />} />
              <Route path="log" element={<Log />} />
            </Route>) : null}
          </Routes>
        </div>
    </>
  );
}

export default Protected;
