import React, { useCallback, useEffect, useState } from 'react';
import { useAuth } from '../contexts/auth';
import { http } from '../helpers/http';
import { optionsDate } from '../helpers/formatter';
import Spinner from '../components/Spinner/Spinner';
import { estiloElementoLista,estiloLista } from '../helpers/globals';


function Account() {
  const initialState = {
    name: '',
    business: '',
    client: '',
    date: '',
    tUsers: '',
    rName: '',
    mail: '',
    phone: '',
    logo: '',
  };

  const [loading, setLoading] = useState(false);
  const [accountInfo, setAccountInfo] = useState(initialState);



  const styleTituloCard = {
    color: '#999',
    fontSize: '14px',
    marginBottom: '5px',
  };


  // Esta función ya está lista para recibir algún tipo de cambio en los inputs.
  // Únicamente que desde el HTML están desactivados por el momento.
  const handleOnChange = ({ target }) => {
    setAccountInfo({
      ...{ ...accountInfo },
      [target.name]: target.value,
    });
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
  };

  const {
    auth,
  } = useAuth();

  const fetchAccountInfo = useCallback(async () => {
    setLoading(true);
    try {
      const response = await http.get('/records/crea_clientes', {
        params: {
          id_client: auth.id_client,
        },
      });
      const { data } = response;
      // Función Intl para "formatear" la fecha recibida desde el Backend.
      const formattedDate = new Intl.DateTimeFormat('es-MX', optionsDate).format(new Date(data.data[0]?.alta));
      setAccountInfo({
        name: data.data[0]?.name || '',
        business: data.data[0]?.tipo_empresa || '',
        client: data.data[0]?.tipo_cliente || '',
        date: formattedDate || '',
        tUsers: data.data[0]?.usuarios || '',
        rName: data.data[0]?.contacto || '',
        mail: data.data[0]?.correo_contacto || '',
        phone: data.data[0]?.tel_contacto || '',
        logo: data.data[0]?.logo_url || '',
      });
    } finally {
      setLoading(false);
    }
  }, [auth]);

  useEffect(() => {
    fetchAccountInfo();
  }, [fetchAccountInfo]);

  if (loading) {
    return <Spinner />;
  }
  return (
    <>
      {/* <section className="content-header">
        <section className="section-title">
          <h2>Cuenta</h2>
          <p>Datos de Cuenta</p>
        </section>
        <section className="secondary-nav">
          <ul className="nav-tabs" style={estiloLista}>
            <li className=""><a href="/account">Datos de Cuenta</a></li>
          </ul>
        </section>
      </section> */}


      <h4 className="title is-4">Cuenta</h4>
      <p className='mb-5' >Datos de Cuenta</p>

      <div className="columns">
          <div className="column is-one-third " >
            <div className="card">
              <div className="card-content">
               <ul>

                <li style={estiloElementoLista}>
                  <div className="columns is-mobile">
                    <div className="column is-half " style={{display: 'flex',alignItems: 'center'}}>
                      <p className="has-text-weight-bold" style={styleTituloCard} >NOMBRE DE CLIENTE</p>
                    </div>
                    <div className="column is-half has-text-right">
                      <input className="input" type="text" name="name" value={accountInfo.name} onChange={handleOnChange} disabled />
                    </div>
                  </div>
                </li>

                <li style={estiloElementoLista}>
                  <div className="columns is-mobile">
                    <div className="column is-half " style={{display: 'flex',alignItems: 'center'}}>
                      <p className="has-text-weight-bold" style={styleTituloCard} >TIPO DE EMPRESA</p>
                    </div>
                    <div className="column is-half has-text-right">
                      <input className="input" type="text" name="name" value={accountInfo.business} onChange={handleOnChange} disabled />
                    </div>
                  </div>
                </li>

                <li style={estiloElementoLista}>
                  <div className="columns is-mobile">
                    <div className="column is-half " style={{display: 'flex',alignItems: 'center'}}>
                      <p className="has-text-weight-bold" style={styleTituloCard} >TIPO DE CLIENTE</p>
                    </div>
                    <div className="column is-half has-text-right">
                      <input className="input" type="text" name="name" value={accountInfo.client} onChange={handleOnChange} disabled />
                    </div>
                  </div>
                </li>


                <li style={estiloElementoLista}>
                  <div className="columns is-mobile">
                    <div className="column is-half " style={{display: 'flex',alignItems: 'center'}}>
                      <p className="has-text-weight-bold" style={styleTituloCard} >FECHA DE ALTA DE CLIENTE</p>
                    </div>
                    <div className="column is-half has-text-right">
                      <input className="input" type="text" name="name" value={accountInfo.date} onChange={handleOnChange} disabled />
                    </div>
                  </div>
                </li>

                <li style={estiloElementoLista}>
                  <div className="columns is-mobile">
                    <div className="column is-half " style={{display: 'flex',alignItems: 'center'}}>
                      <p className="has-text-weight-bold" style={styleTituloCard} >NOMBRE DE RESPONSABLE</p>
                    </div>
                    <div className="column is-half has-text-right">
                      <input className="input" type="text" name="name" value={accountInfo.rName} onChange={handleOnChange} disabled />
                    </div>
                  </div>
                </li>


                <li style={estiloElementoLista}>
                  <div className="columns is-mobile">
                    <div className="column is-half " style={{display: 'flex',alignItems: 'center'}}>
                      <p className="has-text-weight-bold" style={styleTituloCard} >CORREO ASOCIADO</p>
                    </div>
                    <div className="column is-half has-text-right">
                      <input className="input" type="text" name="name" value={accountInfo.mail} onChange={handleOnChange} disabled />
                    </div>
                  </div>
                </li>

                <li style={estiloElementoLista}>
                  <div className="columns is-mobile">
                    <div className="column is-half " style={{display: 'flex',alignItems: 'center'}}>
                      <p className="has-text-weight-bold" style={styleTituloCard} >TELÉFONO DE CONTACTO</p>
                    </div>
                    <div className="column is-half has-text-right">
                      <input className="input" type="text" name="name" value={accountInfo.phone} onChange={handleOnChange} disabled />
                    </div>
                  </div>
                </li>
              </ul>


             


                {/* <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">Tipo de Cliente</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <p className="control">
                      <input type="text" name="name" value={accountInfo.client} onChange={handleOnChange} disabled />
                      </p>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">Fecha de Alta de Cliente</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <p className="control">
                      <input type="text" name="name" value={accountInfo.date} onChange={handleOnChange} disabled />
                      </p>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label">
                    <label className="label">Nombre de Responsable</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <p className="control">
                      <input type="text" name="name" value={accountInfo.rName} onChange={handleOnChange} disabled />
                      </p>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label">
                    <label className="label">Correo Asociado</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <p className="control">
                      <input type="text" name="name" value={accountInfo.mail} onChange={handleOnChange} disabled />
                      </p>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">Teléfono de Contacto</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <p className="control">
                      <input type="text" name="name" value={accountInfo.phone} onChange={handleOnChange} disabled />
                      </p>
                    </div>
                  </div>
                </div>  */}

              </div>
            </div>
          </div>
        </div>

      {/* <section className="content-body">
        <section className="account-details">
          <div className="account-form box">
            <form onSubmit={handleOnSubmit}>
              <div className="row">
                <label>Nombre de Cliente</label>
                <input type="text" name="name" value={accountInfo.name} onChange={handleOnChange} disabled />
              </div>
              <div className="row">
                <label>Tipo de Empresa</label>
                <input type="text" name="business" value={accountInfo.business} onChange={handleOnChange} disabled />
              </div>
              <div className="row">
                <label>Tipo de Cliente</label>
                <input type="text" name="client" value={accountInfo.client} onChange={handleOnChange} disabled />
              </div>
              <div className="row">
                <label>Fecha de Alta de Cliente</label>
                <input type="text" name="date" value={accountInfo.date} onChange={handleOnChange} disabled />
              </div>
              <div className="row">
                <label>Nombre de Responsable</label>
                <input type="text" name="rName" value={accountInfo.rName} onChange={handleOnChange} disabled />
              </div>
              <div className="row">
                <label>Correo Asociado</label>
                <input type="text" name="mail" value={accountInfo.mail} onChange={handleOnChange} disabled />
              </div>
              <div className="row">
                <label>Teléfono de Contacto</label>
                <input type="text" name="phone" value={accountInfo.phone} onChange={handleOnChange} disabled />
              </div>
            </form>
          </div>
        </section>
      </section> */}
    </>
  );
}

export default Account;
