import React, { useState, useEffect } from 'react';
import {
  Chart as CharJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

CharJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function OS({ osInfo }) {
  const [chartInfo] = useState(osInfo);
  const [chartData, setChartData] = useState({
    datasets: [],
  });
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    setChartData({
      labels: ['Android', 'iOS', 'Otros'],
      datasets: [
        {
          data: chartInfo,
          backgroundColor: ['#ffe0e6', '#d7ecfb', '#fff5dd'],
          borderColor: ['#ff6686', '#3aa4ec', '#ffce5a'],
          borderWidth: 1,
          datalabels: {
            color: '#999',
            anchor: 'end',
            align: 'top',
          },
        },
      ],
    });
    setChartOptions({
      responsive: true,
      plugins: {
        legend: {
          display: false,
          position: 'bottom',
        },
      },
      scales: {
        y: {
            beginAtZero: true,
            suggestedMin: 0,
            suggestedMax: Math.max(...chartInfo)+(Math.max(...chartInfo)*.1),
        }
    }

    });
  }, [chartInfo[0]]);

  if (!chartInfo[0]) {
    return <>Esta gráfica no está disponible por el momento ❌</>;
  }

  return (
    <>
      <Bar options={chartOptions} data={chartData} width={100} height={100} />
      <ul className="legends ml-0">
        <li>
          <small style={{ background: 'rgb(255 224 230)', border: '2px solid rgb(255 133 160)' }} />
          <strong>{`Android: ${chartInfo[0]} %`}</strong>
        </li>
        <li>
          <small style={{ background: 'rgb(215 236 250)', border: '2px solid rgb(135 199 243)' }} />
          <strong>{`iOS: ${chartInfo[1]} %`}</strong>
        </li>
        <li>
          <small style={{ background: 'rgb(255 245 222)', border: '2px solid rgb(255 221 142)' }} />
          <strong>{`Otros: ${chartInfo[2]} %`}</strong>
        </li>
      </ul>
    </>
  );
}

export default OS;
