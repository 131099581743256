import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { http } from '../../helpers/http';

import Days from './AnalyticsComponents/Days';
import Impressions from './AnalyticsComponents/Impressions';
import Users from './AnalyticsComponents/Users';
import ScreenBox from './AnalyticsComponents/ScreensBox';
import AgeRange from './AnalyticsComponents/AgeRange';
import HorizontalBar2 from '../Graph/HorizontalBar2';
import AgeAndGenre from './AnalyticsComponents/AgeAndGenre';
import OS from './AnalyticsComponents/OS';
import Device from './AnalyticsComponents/Device';
// import AoW from './AnalyticsComponents/AoW';
// import Topic from './AnalyticsComponents/Topic';
import Spinner from '../Spinner/Spinner';

// Components
import HorizontalBar from '../Graph/HorizontalBar';

// Ya que este componente sirve como un supercontainer, aquí se guardan, en estados locales,
// las respuestas que se reciben de los diferentes endpoints y después se pasan como props a
// los componentes hijos. Ya que de esta manera se optimiza el tiempo de carga, asì como la
// cantidad de requests que se hacen al back.

function Analytics() {

  const styleTituloCard = {
    color: '#999',
    fontSize: '16px',
    marginBottom: '20px',

  };


  const [loadingDias, setLoadingDias] = useState(true);
  const [loadingOS, setLoadingOs] = useState(true);
  const [loadingAge, setLoadingAge] = useState(true);
  const [loadingFamilias, setLoadingFamilias] = useState(true);
  



  const [daysInfo, setDaysInfo] = useState([]);
  const [daysPercentage, setDaysPercentage] = useState(0);
  const [impressionsInfo, setImpressionsInfo] = useState([]);
  const [usersInfo, setUsersInfo] = useState([]);
  const [osInfo, setOsInfo] = useState([]);
  const [deviceNameInfo, setDeviceNameInfo] = useState([]);
  const [deviceValueInfo, setDeviceValueInfo] = useState([]);
  const [ageInfo, setAgeInfo] = useState([]);
  const [femaleInfo, setFemaleInfo] = useState([]);
  const [femaleTotal, setFemaleTotal] = useState(0);
  const [maleInfo, setMaleInfo] = useState([]);
  const [maleTotal, setMaleTotal] = useState(0);
  const [dataFamilias, setDataFamilias] = useState([]);

  const labelsAge = ['-18', '18+', '25+', '35+', '45+', '50+', '55+', '65+']

  // Lo mismo, el ID de la campaña se recibe por params y se le pasa al request del fetch
  const { id: campaignId } = useParams();

  // Esta función recibe nùmeros enteros de un endpoint, los suma y saca un total,
  // para después sacar el porcentaje de cada uno de los números recibidos.
  const percentage = useCallback((arr) => {
    let total = 0;
    const result = [];
    for (let i = 0; i < arr.length; i += 1) {
      total += arr[i];
    }
    for (let i = 0; i < arr.length; i += 1) {
      const p = (arr[i] * 100) / total;
      if (Number.isNaN(p)) {
        result.push(0);
      } else {
        result.push(p.toFixed(1));
      }
    }
    return result;
  }, []);



  async function fetchDiasCampaniaImpresionesImpactados() {
    //const response = await http.get('/kpis/analitica_camp', {
    const response = await http.get('/dashboard_etl/kpi_camp_analitica', {
      params: {
        id_camp: campaignId,
      },
    });
    // Respuesta del primer fecth (Days.jsx, Impressions.jsx y Users.jsx)
    const { data } = response;
    setDaysInfo([data.data.dias.dias_totales, data.data.dias.dias_transcurridos,data.data.dias.total_dias_efectivos, data.data.dias.total_dias_efectivos_transcurridos]);
    setDaysPercentage(data.data.dias.porcentaje_dias);
    setImpressionsInfo([data.data.impresiones.total, data.data.impresiones.promedio]);
    setUsersInfo([data.data.usuarios_impacto.total,
      data.data.usuarios_impacto.unicos,
      data.data.usuarios_impacto.promedio_usu_dia,
      data.data.usuarios_impacto.impact_promedio_usuario]);
    setLoadingDias(false);

  }

  async function fetchOs() {
    const response2 = await http.get('/dashboard_etl/kpi_os_analitica', {
      params: {
        id_camp: campaignId,
      },
    });
    // Respuesta del segundo fetch (OS.jsx y Device.jsx)
    const result = response2.data.data;
    const os = [result.os.Android, result.os.iOS, result.os.Otros];
    setOsInfo([percentage(os)[0],
      percentage(os)[1],
      percentage(os)[2]]);
    const trademark = result.marca.map((el) => el.marca);
    setDeviceNameInfo(trademark);
    const value = result.marca.map((el) => el.porcentaje);
    setDeviceValueInfo(value);
    setLoadingOs(false);
  }

  async function fetchAge() {
    //const response3 = await http.get('kpis/kpi_edad', {
    const response3 = await http.get('/dashboard_etl/kpi_edad_analitica', {
      params: {
        id_camp: campaignId,
      },
    });
    // Respuesta del 3er fetch (AgeRange.jsx y AgeAndGenre.jsx)
    // Son dos gráficas que bàsicamente muestran lo mismo, pero el cliente lo requiere asì...
    const result2 = response3.data.data;
    const female = [result2.edad_genero.mujeres['-18'],
      result2.edad_genero.mujeres['18+'],
      result2.edad_genero.mujeres['25+'],
      result2.edad_genero.mujeres['35+'],
      result2.edad_genero.mujeres['45+'],
      result2.edad_genero.mujeres['50+'],
      result2.edad_genero.mujeres['55+'],
      result2.edad_genero.mujeres['65+'],
    ];
    setFemaleInfo(percentage(female));
    setFemaleTotal(result2.edad_genero.mujeres.totales);
    const male = [result2.edad_genero.hombres['-18'],
      result2.edad_genero.hombres['18+'],
      result2.edad_genero.hombres['25+'],
      result2.edad_genero.hombres['35+'],
      result2.edad_genero.hombres['45+'],
      result2.edad_genero.hombres['50+'],
      result2.edad_genero.hombres['55+'],
      result2.edad_genero.hombres['65+'],
    ];
    setMaleInfo(percentage(male));
    setMaleTotal(result2.edad_genero.hombres.totales);
    setAgeInfo([result2.rangos_edad['-18'],
      result2.rangos_edad['18+'],
      result2.rangos_edad['25+'],
      result2.rangos_edad['35+'],
      result2.rangos_edad['45+'],
      result2.rangos_edad['50+'],
      result2.rangos_edad['55+'],
      result2.rangos_edad['65+'],
      result2.rangos_edad.totales,
    ]);
    setLoadingAge(false);
  }

  async function fetchFamilias() {

    const response4 = await http.get('/dashboard_etl/familias_analitica', {
      params: {
        id_camp: campaignId,
      },
    });
    let data= response4.data;
    data.sort((a,b) =>  a.familia - b.familia); // b - a for reverse sort

    //console.log(data);

    setDataFamilias(data);
    setLoadingFamilias(false);
  }

  


  useEffect(() => {
    fetchDiasCampaniaImpresionesImpactados();
    fetchOs();
    fetchAge();
    fetchFamilias();
  }, []);


  return (
    <>

      <div className="columns" id='info'>
        <div className="column is-one-third">
          <div className="card cien">
            <div className="card-content">
              <p className="has-text-weight-bold" style={styleTituloCard}>DÍAS DE CAMPAÑA</p>
              {
              loadingDias ? <Spinner /> : ( <Days daysInfo={daysInfo} daysPercentage={daysPercentage} />)
              }
            </div>
          </div>
        </div>
        <div className="column is-one-third">
          <div className="card cien">
            <div className="card-content">
              <p className="has-text-weight-bold" style={styleTituloCard}>IMPRESIONES</p>
              {
              loadingDias ? <Spinner /> : ( <Impressions impressionsInfo={impressionsInfo}  />)
              }
              
            </div>
          </div>
        </div>
        <div className="column is-one-third">
          <div className="card cien">
            <div className="card-content">
              <p className="has-text-weight-bold" style={styleTituloCard}>USUARIOS IMPACTADOS</p>
              {
              loadingDias ? <Spinner /> : ( <Users usersInfo={usersInfo}  />)
              }
              
            </div>
          </div>
        </div>
      </div>

      
      <div className="columns" id='info2'>
        <div className="column">
          <div className="card">
            <div className="card-content">
              {
                loadingFamilias ? <Spinner /> : ( <ScreenBox screenData={dataFamilias} />)
              }
            </div>
          </div>
        </div>
      </div>

      <div className="columns is-multiline" id='info3'>

        <div className="column is-one-third-desktop is-half-tablet">
          <div className="card cien">
            <div className="card-content">
              <p className="has-text-weight-bold" style={styleTituloCard}>RANGOS DE EDAD</p>
              {
              loadingAge ? <Spinner /> : ( <HorizontalBar2 arrayData={ageInfo} labels={labelsAge} tableTitle='Hombres y Mujeres' />)
              }

              
            </div>
          </div>
        </div>

        <div className="column is-one-third-desktop is-half-tablet">
          <div className="card cien">
            <div className="card-content">
            <p className="has-text-weight-bold" style={styleTituloCard}>EDAD Y GÉNERO</p>
              {
              loadingAge ? <Spinner /> : ( <HorizontalBar tableTitle='Mujeres Hombres' leftData={femaleInfo} rightData={maleInfo} labels={labelsAge} totalLeft={femaleTotal} totalRight={maleTotal} /> )
              }
            </div>
          </div>
        </div>

        <div className="column is-one-third-desktop is-half-tablet">
          <div className="card cien">
            <div className="card-content">
            <p className="has-text-weight-bold" style={styleTituloCard}>SISTEMA OPERATIVO</p>
              {
              loadingOS ? <Spinner /> : ( <OS osInfo={osInfo} />)
              }
            </div>
          </div>
        </div>

        <div className="column is-one-third-desktop is-half-tablet">
          <div className="card cien">
            <div className="card-content">
              <p className="has-text-weight-bold" style={styleTituloCard}>MARCA DISPOSITIVOS</p>
              {
              loadingOS ? <Spinner /> : ( <Device deviceNameInfo={deviceNameInfo} deviceValueInfo={deviceValueInfo} />)
              }
                
            </div>
          </div>
        </div>
      </div>



      {/* <div className="columns" id='info4'>
        <div className="column is-one-third">
          <div className="card cien">
            <div className="card-content">
              <p className="has-text-weight-bold" style={styleTituloCard}>MARCA DISPOSITIVOS</p>
              {
              loadingOS ? <Spinner /> : ( <Device deviceNameInfo={deviceNameInfo} deviceValueInfo={deviceValueInfo} />)
              }
                
            </div>
          </div>
        </div>
      </div> */}


    </>
  );
}

export default Analytics;
